.sidebar-wrapper{
    background: $white;
    border-radius: 8px;
    margin-bottom: 20px;
    width: 130px;
    position: sticky;
    left: 0;
    top: 0;
    font-family: 'Open Sans', sans-serif;
    z-index: 5;
    .top-list{
        background: linear-gradient(#5BCFED,white);
        padding-bottom: 30px;
    }
    // overflow: hidden;
    font-family: 'Open Sans', sans-serif;
    
    > ul {
        &.top-list{
            background: linear-gradient(#5BCFED,white);
            padding-bottom: 30px;
            border-radius: 8px 8px 0 0;
            > li{
                &:first-child{
                    > a{
                        border-radius: 8px 8px 0 0;
                    }
                }
            }
        }
        &.bottom-list{
            border-radius: 0 0 8px 8px;
            > li{
                &:last-child{
                    > a{
                        border-radius: 0 0 8px 8px;
                    }
                }
            }
        }
        > li{
            position: relative;
            .sub-menu{
                position: absolute;
                right: 0;
                top: 0;
                left: 130px;
                width: 160px;
                background: $white;
                border-radius: 5px;
                overflow: hidden;
                opacity: 0;
                visibility: hidden;
                overflow-y: auto;
                .custom-scrollbar{
                    li{
                        margin-right: 10px;
                    }
                }
                &.bottom-align{
                    bottom: 0;
                    top: auto;
                }
                li{
                    a{
                        min-height: 40px;
                        padding: 10px;
                        display: block;
                        font-size: 13px;
                        color: $black;
                        transition: all 0.3s;
                        min-width: 150px;
                        &:hover{
                            background-color: $grey-shade19;
                            color: $white;
                        }
                    }
                }
            }
            &:hover{
                .sub-menu{
                    opacity: 1;
                    visibility: visible;
                }
            }
            > a{
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 3px 20px;
                img, i ,svg , object{
                    height: 22px;
                    width: 22px;
                    font-size: 22px;
                    color: $black;
                    fill: $grey-shade19;
                }
                svg:root {
                    fill: $grey-shade19 !important;
                }
                svg path{
                    fill: green;
                  }
                span{
                    font-size: 16px;
                    color: $grey-shade19;
                }
                .hover-img,.dark-theme-img{
                    display: none;
                }
                &:hover{
                    background-color: $grey-shade19;
                    span{
                        color: $white;
                    }
                    .hover-img{
                        display: inline-block;
                    }
                    .normal-img{
                        display: none;
                    }
                    i{
                        color: $blue-shade-4;
                    }
                }
            }
        }
        .sidebar-logo{
            a{
                &:hover{
                    background-color: $white;
                }
            }
            img{
                width: 50px;
                height: auto;
            }
            .dark-theme{
                display: none;
            }
        }
    }
}
