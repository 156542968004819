.mobile-preview-box{
  .device-container {
    width: 400px;
    max-width: 100%;
    margin: 0 auto;
  }

  .device-wrap {
      position: relative;
      width: 100%;
      padding-bottom: 205%;
  }

  .device-wrap .device {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('../../../../assets/images/phone-frame.png') no-repeat center/cover;
  }

  .device-wrap .screens {
      position: absolute;
      top: 11.8%;
      bottom: 11.8%;
      left: 6%;
      right: 6%;
      overflow: hidden;
  }

  .device-container .carousel-indicators {
      bottom: 5px;
      margin-bottom: 0;
  }

  .device-container .carousel-indicators li, .device-container .carousel-indicators .active {
      margin: 0;
  }

  .carousel{
    height: 100%;
    .carousel-control-prev, .carousel-control-next{
      display: none;
    }
    .carousel-indicators{
      bottom: 5px;
      margin-bottom: 0;
      [data-bs-target]{
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
        border: 0;
        opacity: 1;
        margin: 3px;
        &.active{
          background: #6e91d0;
        }
      }
    }
    .carousel-inner{
      height: 100%;
      .carousel-item{
        height: 100%;
      }
    }
    .carousel-caption{
      // width: 330px;
      height: calc(100% - 30px);
      margin: auto;
      position: static;
      padding-bottom: 0;
      padding-top: 0;
      overflow: auto;
    }
  }

  .app-header {
    height: 56px;
    width: 100%;
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    &:before {
        content: '';
        display: block;
        width: 50%;
        border: 0.5px solid currentColor;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    &:after {
        content: '';
        display: block;
        width: 50%;
        border: 0.5px solid currentColor;
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .menu-btn {
        position: absolute;
        left: 10px;
        background: none;
        border: none;
        font-size: 30px;
        cursor: pointer;
        img{
          width: 22px;
          height: auto;
        }
    }
    .app-logo {
        cursor: pointer;
        width: 60px;
        img {
            width: 100%;
        }
    }
  }

  .app-body {
      width: 100%;
      height: auto;
      padding: 15px;
  }

  .app-body .app-body-title {
      text-align: center;
      margin-top: 7px;
      font-style: italic;
      font-weight: 600;
      font-size: 15px;
  }

  .app-body-menu {
      width: 100%;
      height: calc(100% - 50px);
      padding: 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-flow: wrap;
      flex-flow: wrap;
      margin-top: 20px;
  }

  .menu-item {
      width: 50%;
      height: 160px;
      text-align: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      position: relative;
      cursor: pointer;
  }

  .menu-item .alr-not {
      position: absolute;
      top: 5px;
      right: 20px;
      background: red;
      width: 20px;
      height: 20px;
      line-height: 1;
      border-radius: 15px;
      color: #fff;
      font-weight: 600;
      padding: 3px;
      font-size: 14px;
  }

  .menu-item i {
      font-size: 50px;
  }

  .menu-item .menu-name {
      margin: 0;
      font-size: 15px;
      margin-top: 6px;
      font-weight: 600;
      color: currentColor;
  }
  .menu-item svg path{
    fill: currentColor;
  }
  .menu-item .box-border{
    border-color: currentColor !important;
  }

  .w-80{
    width: 80%;
  }
  .h-80{
    height: 80%;
  }
  .app-footer {
    height: 50px;
    width: 100%;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .sp-label {
    font-size: 14px;
    margin: 0px;
    font-style: italic;
    color: #45e1f3;
  }
  .sp-name {
    font-size: 16px;
    margin: 0px;
    font-weight: 600;
    color: #45e1f3;
  }

  .carousel-caption .wrap-2 .head p {
    font-size: 20px;
    line-height: 26px;
    text-shadow: none
  }

  .app-service-form-list{
    ul{
      padding: 0 10px;
      li{
        border-radius: 3rem;
        text-shadow: none;
        -webkit-box-shadow: 1px 1px 4px 2px #777777b3;
        box-shadow: 1px 1px 4px 2px #777777b3;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        overflow: hidden;
        .left{
          width: 33.33%;
          display: flex;
          padding: 10px;
          flex-direction: column;
          justify-content: center;
          border-top-left-radius: 3rem;
          border-bottom-left-radius: 3rem;
          text-shadow: none;
        }
        .right{
          width: 66.66%;
          background-color: #ffffff;
          border-top-right-radius: 3rem;
          border-bottom-right-radius: 3rem;
          text-shadow: none;
          color: #e5e5e5;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-right: 20px;
          position: relative;
          .arrow-btm {
            border: solid #212121;
            border-width: 0 3px 3px 0;
            display: inline-block;
            position: absolute;
            padding: 3px;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            right: 20px;
            top: 50%;
            margin-top: -5px;
          }
        }
        &.text-box{
          border-radius: 1rem;
          padding-left: 1.5rem;
          .right{
            width: 100%;
            height: 90px;
            padding-top: 10px;
            justify-content: flex-start;
            align-items: flex-start;
            border-radius: 1rem;
            h2{
              font-size: 20px;
              color: #e5e5e5;
            }
          }
        }
      }
    }
  }
  .button-box {
    button{
      width: 120px;
      height: 35px;
      border-radius: 3rem;
      border: 0;
      margin: 10px 10px 5px;
      color: #ffffff;
      box-shadow: 1px 1px 11px 0 #777777b3;
    }
  }
  .ab-info-box {
    box-shadow: 1px 1px 16px 4px #eaeaea;
    margin: 10px 5px;
    padding: 15px 15px 15px 40px;
    display: flex;
    text-align: left;
    border-bottom: 2px solid;
    .ab-info-title{
      width: 35px;
      flex-shrink: 0;
      font-size: 20px;
    }
    .info.value{
      width: 100%;
      color: #636363;
      font-size: 12px;
      font-weight: 600;
      .info-label{
        padding-bottom: 5px;
        display: block;
      }
      .cs-font{
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
}