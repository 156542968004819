.background-gradient {
  background: linear-gradient(90deg, #5BCFED, black) no-repeat;
  color: white;
  width: auto;
  display: inline-block;
  padding: 6px;
}

.pricingTable {
  padding: 5px 0 0;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-box-shadow: 0 0 0 1px #e5e5e5, 0 6px 0 #31b0b9;
  box-shadow: 0 0 0 1px #e5e5e5, 0 6px 0 #31b0b9;
  max-width: 300px;
  border-radius: 5px;
  h3{
    font-size: 18px;
    font-weight: 700;
    padding: 8px 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0 0 0 0;
  }
  .price-value {
    padding: 0 15px;
    font-size: 30px;
    line-height: 1;
    margin-bottom: 8px;
    .month{
      font-size: 20px;
      color: #fe6c6c;
    }
  }
  .pricing-content {
    padding: 30px 0 5px;
    z-index: 1;
    position: relative;
    &:after,
    &:before {
      content: "";
      width: 51%;
      height: 100%;
      background: #fff;
      border-radius: 0 50px 0 5px;
      border-top: 3px solid #e5e5e5;
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
    }
    &:after {
      border-radius: 50px 0 5px 0;
      left: auto;
      right: 0;
    }
    ul{
      margin-bottom: 15px;
      li{
        font-size: 17px;
        font-weight: 600;
        color: #4f384d;
        line-height: 40px;
        letter-spacing: 1px;
        border-bottom: 1px solid #c5d2d4;
        &:last-child{
          border-bottom: 0;
        }
      }
    }
  }
  .pricingTable-signup {
    a, button{
      display: inline-block;
      padding: 7px 20px;
      font-size: 14px;
      font-weight: 700;
      color: #4f384d;
      text-transform: uppercase;
      border-top: 0;
      border-bottom: 0;
      background: transparent;
      border-left: 2px solid #4f384d;
      border-right: 2px solid #4f384d;
      position: relative;
      -webkit-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      &:after,
      &:before {
          content: "";
          width: 0;
          height: 2px;
          background: #222e44;
          position: absolute;
          top: 0;
          left: 0;
          -webkit-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
      }
      &:after{
        top: auto;
        left: auto;
        bottom: 0;
        right: 0;
      }
      &:hover{
        &:before,
        &:after{
          width: 100%;
        }
      }
    }
  }
  &.active{
    -webkit-box-shadow: 0 0 0 1px #61ddf2;
    box-shadow: 0 0 0 1px #61ddf2;
    background-color: #0dc4ce;
    .pricing-content{
      &:before,
      &:after{
        border-top: 0;
      }
    }
    .price-value{
      color: #065b5f;
      .month{
        color: #2e3c55;
      }
    }
  }
  &:hover{
    -webkit-box-shadow: 0 0 50px 0 #e5e5e5;
    box-shadow: 0 0 50px 0 #e5e5e5;
  }
}

.price-summary{
  border-right: 3px solid #000000;
  .price-summary-title{
    border-bottom: 3px solid #000000;
  }
  td{
    padding: 10px;
  }
  h3{
    font-size: 20px;
  }
  .bg-inverse{
    background: #f1f1f1;
    color: #333;
  }
}

.stripe-form{
  .StripeElement{
    padding-top: 11px;  
  }
}
.iconic-input{
  position: relative;
  i {
    color: #CCCCCC;
    display: block;
    font-size: 15px;
    height: 16px;
    margin: auto 7px;
    position: absolute;
    text-align: center;
    width: 16px;
    top: 0;
    bottom: 0;
  }
  input {
    padding-left: 30px !important;
  }
}
.powered-by{
  display: flex;
  align-items: center;
  label{
    margin-right: 10px;
    margin-bottom: 0;
  }
  img{
    height: 30px;
    width: auto;
  }
}
.bg-primary{
  background: #1cc9e4 !important;
}

.pricingTable {
  .pricing-content {
    li {
      span {
        display: block;
        border-top: 1px solid #c5d2d4;
      }
    }
  }
}

.counter{
  margin-left: 60px;
  max-width: 100px;
  margin-top: 10px;
}