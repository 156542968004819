.d-flex {
  display: flex;
  flex-wrap: wrap;
}

.no-wrap {
  flex-wrap: nowrap;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center !important;
}

.justify-flex-start {
  justify-content: flex-start !important;
}
.justify-flex-end {
  justify-content: flex-end !important;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.direction-column {
  flex-direction: column;
}

.border-right {
  border-right: 1px solid $grey-shade13;
}

.page-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;

  img {
    max-width: 100px;
  }
}

.main-wrapper {
  background: $grey-shade4;
  padding: 10px 0;
}

.page-wrapper {
  display: flex;
  align-items: flex-start;

  .right-section-wrapper {
    padding: 15px;
    flex: 1;
  }

  font-family: "Open Sans", sans-serif;
}

.hide {
  display: none !important;
}

.table-header {
  align-items: flex-start;

  .top-block {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .table-header-tabs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tab-content {
    width: 100%;
    background: $white;
    margin-top: 10px;
    padding: 10px;
  }

  .table-title {
    display: flex;
    align-items: center;
    color: $black-shade-2;
    margin-top: 5px;
    position: absolute;
    left: 0;

    img {
      width: 30px;
      height: 30px;
      margin-right: 5px;
    }

    span {
      font-size: 18px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 1px;
      line-height: 1;
    }
  }

  .table-header-tabs {
    font-family: "Open Sans", sans-serif;

    .nav-tabs {
      border: none;
      border: none;
      background: $white;
      border-radius: 10px 10px 0 0;

      .nav-link {
        border: none;
        min-height: 48px;
        width: 186px;
        color: $black-shade-2;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-bottom: 3px solid transparent;
        margin-bottom: 0;
        transition: all 0.3s;

        &.active {
          border-bottom: 3px solid #12b0c8;
        }

        &:hover {
          background-color: #eaeaea;
          border-bottom: 3px solid #12b0c8;
        }
      }
    }
  }
}

.video-link-outer {
  min-width: 155px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 0;

  .video-tutorial {
    position: relative;
    padding: 0 10px 0 5px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    margin-top: 5px;
    cursor: pointer;

    .fa-icon {
      border: 1px solid #52daee;
      border-radius: 50%;
      padding: 5px;
      font-size: 20px;
      height: 30px;
      width: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;

      &:before {
        content: "?";
        color: #000;
        transition: all 0.3s;
        font-family: "Open Sans";
        font-weight: 600;
      }
    }

    span {
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    .tutorial-title {
      position: absolute;
      left: 100%;
      top: 0;
      height: 20px;
      margin: auto 0;
      bottom: 0;
      color: #000;
      opacity: 0;
      transition: 0.25s ease-in-out;
      text-transform: none;
      white-space: nowrap;
      letter-spacing: normal;
    }

    &:hover {
      border: 1px solid #52daee;
      transition: all 0.3s;

      .help-text {
        display: none;
      }

      .fa-icon {
        border: none;
        border-radius: 0;

        &:before {
          content: "\f16a";
          color: #11cfed;
          font-family: "FontAwesome";
        }
      }

      .tutorial-title {
        left: 0;
        opacity: 1;
        position: relative;
      }
    }
  }
}

// table css
.table-search-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: 5px;
  position: relative;
  font-family: "Open Sans", sans-serif;

  .search-label {
    width: 300px;
  }

  .form-control {
    border: 1px solid #ddd;
    border-radius: 20px;
    height: 35px;
    width: 300px;
    padding: 10px 20px;
    font-size: 14px;
    color: #555;
    &::placeholder {
      font-weight: 500;
      font-size: 12px;
      color: $grey-shade2;
      opacity: 0.5;
    }
  }
  .btn-primary {
    position: absolute;
    top: 0;
    right: 0;
    height: 35px;
    line-height: 1;
    font-size: 14px;
    span {
      margin-right: 10px;
      font-size: 23px;
      line-height: 0;
      top: 3px;
      position: relative;
    }
  }
  .dd-menu{
    right: 0;
  }
}
.react-bootstrap-table {
  .expand-cell-header {
    opacity: 0;
    visibility: hidden;
  }
  .table-bordered {
    > :not(caption) > * > * {
      border: none;
    }
    thead {
      tr {
        border-top: none;
        border-bottom: 1px solid #52daee !important;
        th {
          padding: 12px 5px;
          font-size: 14px;
          font-weight: 400;
          color: #323232;
        }
      }
    }
    tbody {
      border-top: none;
      tr {
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          background-color: #e2e2e2;
        }
        td {
          padding: 8px 5px;
          vertical-align: middle;
          color: #323232;
          font-weight: 300;
        }
      }
    }
  }
  .toggle-hover {
    display: flex;
    justify-content: center;
    position: relative;
    &:hover {
      .dropdown-list {
        opacity: 1;
        visibility: visible;
        z-index: 4;
      }
    }
    .btn {
      padding: 0;
      min-width: 32px;
      width: 32px;
      height: 32px;
      border-radius: 0;
      max-width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        color: #1cc9e4;
        font-size: 20px;
      }
    }
    .dropdown-list {
      list-style-type: none;
      background: #fff;
      border: 1px solid rgba(0, 0, 0, 0.15);
      position: absolute;
      padding: 5px 0;
      min-width: 138px;
      right: 0;
      top: 100%;
      opacity: 0;
      visibility: hidden;
      z-index: 2;
      a,
      button {
        display: flex;
        align-items: center;
        padding: 8px 5px 8px 20px;
        transition: all 0.3s;
        color: #333333;
        font-size: 15px;
        font-weight: 400;
        .red-bg {
          height: 22px;
          width: 22px;
          border-radius: 50%;
          background-color: #e64b3b;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          i {
            font-size: 16px;
            height: auto;
            width: auto;
            margin: 0;
            color: $white;
          }
        }
        i ,img{
          font-size: 20px;
          margin-right: 10px;
          height: 20px;
          width: 20px;
        }
        &:hover {
          background-color: #1cc9e4;
          i {
            color: $white !important;
          }
        }
        &.delete-link {
          &:hover {
            background-color: #e64b3b;
          }
        }
      }
      button {
        border: 0;
        background: transparent;
      }
      &.large-width{
        min-width: 180px;
      }
    }
    .icon-wrapper{
      min-width: 40px;
    }
  }
}
.table-pagination {
  display: flex;
  justify-content: space-between;
  color: #333333;
  .dropdown {
    padding: 0;
    border-radius: 0;
    .dropdown-toggle {
      background-color: transparent !important;
      border-color: $dark-gray-1 !important;
      color: $grey-shade1;
      font-size: 12px;
      min-width: 64px;
      margin: 0 5px;
    }
    .dropdown-menu {
      min-width: 64px;
      margin-left: 5px;
    }
    .dropdown-item {
      font-size: 12px;
      padding: 0;
      a {
        padding: 0 10px;
        text-align: left;
        display: block;
        width: 100%;
        color: #555;
      }
      &:hover {
        background-color: #1cc9e4;
        a {
          color: #fff;
        }
      }
    }
  }
  .pagination {
    .page-item {
      &.active,
      &:hover {
        .page-link {
          background-color: #1cc9e4;
          color: #fff;
        }
      }
    }
    .page-link {
      border-radius: 16px;
      margin-right: 5px;
      border: none;
      height: 32px;
      min-width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #5e5e5e;
      cursor: pointer;
    }
  }
}

.rounded-tabs {
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  .nav-tabs {
    justify-content: center;
    border-radius: 20px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
    align-self: center;
    border: none;
    .nav-link {
      border: none;
      background-color: transparent;
      margin: 0;
      padding: 12px 14px;
      border-radius: 50px;
      font-weight: 600;
      color: $black;
      font-size: 12px;
      line-height: 14px;
      min-width: 82px;
      &.active {
        background-color: $black;
        color: $white;
      }
    }
  }
  .tab-content {
    margin-top: 12px;
    border-top: 1px solid $dark-gray;
  }
}
.panel-wrapper {
  padding: 0 15px;
  margin-bottom: 30px;
  .panel {
    display: flex;
    padding: 15px;
    box-shadow: 0 0 22px 0 #d8d8d8;
    border-radius: 5px;
    .number-plate {
      display: inline-block;
      background-color: $grey-shade8;
      position: relative;
      padding: 6px 15px;
      min-width: 160px;
      min-height: 45px;
      border-radius: 10px 0 10px 0;
      z-index: 0;
      display: flex;
      justify-content: center;
      margin-right: 15px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 1px;
        top: 0;
        bottom: 0;
        right: -15px;
        height: 100%;
        background-color: $dark-gray-2;
      }
      span {
        font-size: 30px;
      }
    }
    .panel-right {
      padding-left: 15px;
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: space-between;
      .top-block {
        margin-bottom: 10px;
      }
      i {
        font-size: 16px;
        color: $blue-shade-4;
        margin-right: 15px;
      }
      span {
        margin-right: 15px;
      }
      .btn-black {
        height: 34px;
        line-height: 1;
        font-size: 14px;
        span {
          margin-right: 0;
        }
        i {
          color: $white;
          margin-right: 5px;
        }
      }
    }
  }
}
.custom-tabs {
  .nav-tabs {
    border: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .nav-item {
      flex: 0 0 calc(50% - 1px);
      max-width: calc(50% - 1px);
    }
    .nav-link {
      border: none;
      background-color: transparent;
      margin: 0;
      padding: 12px 14px;
      border-radius: 0;
      font-weight: 400;
      color: $black;
      font-size: 14px;
      line-height: 14px;
      width: 100%;
      border-bottom: 3px solid $grey-shade22;
      border-radius: 4px 4px 0 0;
      &.active {
        background-color: $grey-shade23;
        text-transform: uppercase;
        border-bottom: 3px solid $blue-shade-11;
      }
      &:hover {
        background-color: $grey-shade23;
        text-transform: uppercase;
      }
    }
  }
}

.panel-body {
  padding: 15px !important;
}

.panel.widget {
  -webkit-box-shadow: 0 0 22px 0 #d8d8d8;
  box-shadow: 0 0 22px 0 #d8d8d8;
  border-radius: 5px;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-word;
}

.panel.widget p {
  color: inherit;
}

.hide {
  display: none;
}

.show {
  display: inline-block;
}

.spinner-loader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Rounded pill for status
.rounded-pill {
  border-radius: 50rem !important;
  font-size: 12px !important;
  padding: 0.2em 0.6em 0.3em;
  color: white !important;
  font-weight: 700;
  line-height: 1;
}

.pill-open {
  background: #8d22d9;
}

.pill-won {
  background: #09f80c;
}

.pill-lost {
  background: #f21616;
}
.pill-success{
  background-color: $green-shade1;
}

.has-white-bg {
  background-color: #ffffff;
  margin-left: 15px;
  border-radius: 8px;
  padding: 20px !important;
  &.has-heading-block {
    padding: 0 !important;
    border-radius: 10px;
    .content-wrapper {
      padding: 15px;
    }
  }
}
.page-heading-block {
  background-color: $blue-shade-4;
  border-radius: 10px 10px 0 0;
  padding: 15px;
  h5 {
    font-size: 24px;
    font-family: "Source Sans Pro", sans-serif;
    color: $white;
  }
}
.header-block {
  box-shadow: 0 0 22px 0 #d8d8d8;
  border-radius: 5px;
  .top-block {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #f3f3f3;
    .btn-wrapper {
      .btn {
        font-size: 14px;
        margin-left: 10px;
        min-width: 71px;
        height: 34px;
        line-height: 1;
      }
    }
  }
  .bottom-block {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-black {
      height: 32px;
      line-height: 1;
      min-width: 100px;
      font-size: 14px;
    }
  }
  .table-title {
    display: flex;
    align-items: center;
    .customer-name {
      span {
        font-size: 15px;
        letter-spacing: 1px;
        color: #115997;
        &.name,
        &.number {
          cursor: pointer;
        }
        &.is-copy-job {
          height: 36px;
          width: 36px;
          border-radius: 100%;
          align-items: center;
          justify-content: center;
          background-color: #000000;
          display: inline-flex;
          color: #ffffff;
        }
      }
    }
    .job-id {
      color: #f00 !important;
      font-style: italic;
    }
    .booking-type {
      color: #232425 !important;
    }
    img {
      height: 23px;
      width: 23px;
      margin-right: 5px;
    }
    span {
      font-size: 18px;
      text-transform: uppercase;
      line-height: 1;
    }
  }
}
.stepper-list {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  flex: 1;
  li {
    border-radius: 4px 4px 0 0;
    a,
    span {
      border: none;
      min-height: 40px;
      color: #232425;
      font-size: 14px;
      border-bottom: 3px solid transparent;
      margin-bottom: 0;
      transition: all 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 15px;
      border-bottom: 3px solid $grey-shade24;
      margin-right: 2px;
      border-radius: 4px 4px 0 0;
      cursor: pointer;
    }
    &.active {
      cursor: initial;
      a,
      span {
        text-transform: uppercase;
        background-color: #eaeaea;
        border-bottom: 3px solid #12b0c8;
      }
    }
    &:hover {
      text-transform: uppercase;
      background-color: #eaeaea;
    }
  }
  &.has-disable-link {
    li {
      cursor: not-allowed;
      a {
        pointer-events: none;
      }
    }
  }
}
.stepper-content {
  padding: 35px 0px;
  .form-detail-outer {
    display: flex;
    flex-wrap: wrap;
    .block-wrapper {
      flex: 0 0 50%;
      max-width: 50%;
      @include lg {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .panel-heading {
        padding: 0 15px 10px;
        display: block;
        border-bottom: 1px solid $grey-shade13;
      }
    }
    .left-block {
      border-right: 1px solid $grey-shade13;
    }
    .customer-detail-block {
      display: flex;
      padding: 25px 15px;
      .customer-detail {
        flex: 1;
        a{
          color: $blue;
          &:hover{
            color: $blue-shade-5;
          }
        }

        .top-block {
          display: flex;
          justify-content: space-between;
          a{
            background: transparent;
          }
          .contact,
          .email {
            display: flex;
            align-items: center;
            i {
              margin-right: 5px;
            }
          }
        }
      }
      .grey-btn {
        i {
          font-size: 14px;
          color: $black-shade-3;
          margin-left: 5px;
        }
        &:hover {
          i {
            color: $white;
          }
        }
      }
      &.rego-detail {
        .top-block {
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 5px;
          .name {
            margin-right: 10px;
          }
          .is_rego_verified {
            color: green;
          }
        }
        .search-wrapper {
          background: $grey-shade8;
          padding: 4px 4px 4px 10px;
          display: inline-block;
          width: auto;
          border-radius: 5px 0px;
          span {
            color: $black-shade-3;
            font-weight: 700;
          }
          .btn-gradient {
            margin-left: 10px;
            border-radius: 2px;
            height: 32px;
            line-height: 1;
            i {
              color: $white !important;
              font-size: 14px;
            }
          }
        }
      }
    }

    .title-block {
      padding: 10px 15px;
      border-bottom: 1px solid $grey-shade13;
      display: block;
      width: 100%;
      margin-bottom: 15px;
    }
  }
}
.profile {
  height: 36px;
  width: 36px;
  min-width: 36px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $grey-shade16;
  margin-right: 10px;
  overflow: hidden;
  i {
    font-size: 20px;
    color: $blue-shade-4;
  }
}
.panel-heading {
  font-size: 13px;
  color: $black-shade-2;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.general-search-wrapper {
  margin-bottom: 25px;
  .form-group {
    position: relative;
    max-width: 500px;
    .form-control {
      border: 1px solid $dark-gray;
      border-radius: 20px;
      height: 40px;
      padding: 6px 200px 6px 30px;
      &::placeholder {
        font-weight: 500;
        font-size: 12px;
        color: $grey-shade2;
      }
    }
    .btn-primary {
      position: absolute;
      top: 0;
      right: 0;
      height: 40px;
      font-size: 14px;
      min-width: 211px;
      span {
        margin-right: 10px;
        font-size: 23px;
        line-height: 0;
        top: 3px;
        position: relative;
      }
    }
  }
  &.active-search-list {
    .search-list {
      opacity: 1;
      visibility: visible;
      background-color: $white;
      width: 100%;
    }
  }
  .search-list {
    z-index: 2;
    max-width: 500px;
    box-shadow: 0 10px 13px -8px rgba(0, 0, 0, 0.2);
    position: absolute;
    opacity: 0;
    visibility: hidden;
    li {
      a {
        border: none;
      }
    }
  }
}

.has-table-content {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1;
}
.create-job-form {
  .left-block {
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding-right: 20px;
    border-right: 1px solid #dee2e6 !important;
    padding: 0 15px;
    @include lg {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .select-block {
      flex: 0 0 calc(50% - 10px);
      max-width: calc(50% - 10px);
      margin-bottom: 15px;
      .form-group {
        width: 100%;
        .custom-select {
          border: 1px solid #e1eaea;
          padding: 0;
          height: auto;
          border-radius: 4px;
          font-weight: 400;
          background-color: transparent;
          color: #555;
          font-size: 12px;
        }
      }
      label {
        font-size: 14px;
        color: $black-shade-3;
        margin-bottom: 5px;
      }
    }
    .btn-wrapper {
      flex: 0 0 calc(50% - 10px);
      max-width: calc(50% - 10px);
      .btn {
        width: 100%;
      }
    }
  }
  .right-block {
    padding-left: 20px;
    flex: 0 0 50%;
    max-width: 50%;
    @include lg {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .date-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      .form-control {
        min-width: 245px;
      }
    }
    .btn-wrapper {
      display: flex;
      align-items: center;
      .msg-title {
        margin-right: 20px;
        display: flex;
        align-items: center;
        i {
          color: #1cc9e4;
          font-size: 20px;
          margin-right: 10px;
        }
      }
      .btn-outer {
        margin-right: 20px;
      }
    }
  }
  .grey-btn {
    height: 34px;
    line-height: 1;
    font-size: 14px;
    min-width: 150px;
  }
}
.search-list {
  overflow-x: hidden;
  li {
    a {
      padding: 10px 20px 10px 12px;
      font-size: 14px;
      display: block;
      color: $black-shade-3;
      border-bottom: 1px solid $grey-shade13;
      transition: all 0.3s;
      border-left: 5px solid transparent;
      .title {
        margin-right: 5px;
      }
      &:hover {
        background-color: $blue-shade-8;
        color: $white;
      }
    }
    &.active {
      a {
        border-left: 5px solid $blue-shade-9;
        font-weight: 700;
      }
    }
  }
  .thumb-vertical {
    right: 0 !important;
  }
  &.with-icon {
    li {
      a {
        border-left: none;
        &:hover {
          .add-icon {
            background-color: $blue-shade-7;
            border-color: $white;
          }
        }
      }
    }
    .add-icon {
      height: 31px;
      width: 31px;
      min-width: 31px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $black;
      border-radius: 100%;
      margin: 5px;
    }
  }
}
.filter_ic_customer_blue {
  height: 100%;
  width: 6%;
}
.job-description-save-btn {
  position: absolute;
  right: 34px;
}
.key-tag-block {
  border-bottom: 1px solid #dee2e6;
  label {
    min-width: auto;
    margin-right: 20px;
  }
  .form-select {
    width: 200px;
  }
}
.chart-data {
  thead {
    border-top: 1px solid $dark-gray;
    th {
      font-size: 14px;
      font-weight: 400;
      color: $black-shade-3;
    }
  }
  tbody {
    border-top: 1px solid #e8e8e8 !important;
  }
}
.parts-service-tab {
  .btn-block {
    .left-block {
      .btn {
        background: none;
        padding: 0;
        margin-right: 10px;
      }
      .custom-switch{
        margin-top: -5px;
      }
      img {
        max-width: 40px;
        background-color: #efefef;
      }
    }
    .right-block {
      .fa-plus {
        margin-right: 5px;
      }
      .btn-primary {
        font-size: 14px;
      }
    }
  }
  .btn-wrapper {
    margin: 25px 0;
    .btn {
      height: 34px;
      line-height: 1;
      font-size: 14px;
      min-width: 72px;
      i {
        margin-right: 5px;
      }
    }
  }
  .custom-accordion {
    .accordion-item {
      .react-bootstrap-table {
        .table-bordered {
          tbody {
            tr {
              td{
                padding: 8px;
              }
            }
          }
          thead {
            tr {
              th{
                padding: 8px;
              }
            }
          }
        }
      }
      .accordion-header {
        .accordion-button{
          justify-content: flex-start;
          img{
            height: 20px;
            width: 20px;
            box-shadow: 0 0 0 1px $white, 0 0 0 3px $green-shade3;
            border-radius: 100%;
            margin-right: 15px;
          }
          span{
            padding-bottom: 5px;
            border-bottom: 2px solid $green-shade1;
            margin-right: 20px;
          }
          i{
            color: $green-shade1;
          }
          &:not(.collapsed){
            background: $green-shade1 !important;
            span{
              padding-bottom: 0;
              border: none;
            }
          }
        }
      }
      .m-r-50{
        margin-right: 50px;
      }
      .custom-check{
        margin-bottom: 0;
      }
      .check-icon{
        position: absolute;
        left: 0;
        img{
          height: 20px;
          width: 20px;
          box-shadow: 0 0 0 1px $white, 0 0 0 3px $green-shade3;
          border-radius: 5px;
          margin-right: 15px;
        }
      }
    }
  }
}

.draggable-table {
  .small-input {
    width: 90px;
    &.width-50 {
      max-width: 50px;
      text-align: center;
    }
  }
  .form-control {
    height: 42px;
    font-size: 12px;
    color: #555;
  }
  .part-name {
    position: relative;
    button {
      position: absolute;
      right: 10px;
      top: 11px;
      border: 1px solid $blue-shade-7;
      height: 20px;
      min-width: 20px;
      padding: 3px;
      border-radius: 10px;
      background-color: transparent;
      line-height: 1;
      color: $blue-shade-7;
      transition: all 0.3s;
      overflow: hidden;
      span {
        opacity: 0;
        left: 100%;
        white-space: nowrap;
        position: absolute;
        transition: all 0.3s;
        margin: 0 5px;
        color: $blue-shade-7;
        font-weight: 600;
      }
      &:hover {
        span {
          left: 0;
          top: -1px;
          opacity: 1;
          position: relative;
        }
      }
    }
  }
  .react-bootstrap-table {
    .table-bordered {
      tbody {
        tr:hover {
          background-color: transparent;
        }
      }
      tr {
        td {
          padding: 5px;
          min-height: 54px;
        }
      }
    }
  }
  .select-wrapper {
    .form-group {
      width: 100%;
    }
  }
  .description-input {
    width: 100%;
  }
  .toggle-hover {
    li {
      a {
        &:hover {
          color: $white;
        }
      }
    }
  }
}
.comment-section {
  .top-block {
    margin-bottom: 30px;
    .select-wrapper {
      .form-group {
        width: 245px;
        margin-right: 50px;
      }
    }
    .textarea-block {
      button {
        border: none;
        height: 31px;
        width: 31px;
        margin-left: 15px;
      }
    }
  }
  .textarea-wrapper {
    width: 100%;
    position: relative;
    .btn-success {
      font-size: 14px;
      width: 110px;
      height: 34px;
      line-height: 1;
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 999;
    }
  }
}
.pricing-detail {
  .heading-block {
    padding: 10px 20px;
    border-bottom: 1px solid $grey-shade24;
    margin-bottom: 20px;
    h3 {
      font-size: 20px;
    }
  }
  .left-block {
    flex: 0 0 67%;
    max-width: 67%;
    border-right: 1px solid $grey-shade13;
    padding-right: 15px;
  }
  .right-block {
    padding-left: 15px;
    flex: 33%;
    max-width: 33%;
  }
  .custom-switch {
    .form-check-input {
      height: 28px;
      width: 50px;
      background-size: 30px;
    }
  }
  &.secondary-pricing-block{
    border-radius: 10px;
    box-shadow: 0 0 20px 0 $grey-shade24;
    border: 1px solid $grey-shade24;
    .heading-block{
      background-color: $blue-shade-2;
      border-radius: 10px 10px 0 0;
      h3{
        color: $white;
      }
    }
    .content-wrapper{
      padding: 0 20px;
    }
  }
}
.accordion-collapse {
  width: 100%;
}
.accordion-header {
  button {
    font-size: 13px;
    color: #232425 !important;
    background: $white !important;
    letter-spacing: 1px;
  }
}
.custom-table-footer {
  margin: 30px 10px 30px 0;
  .mr-10 {
    margin-right: 10px;
  }
  .btn {
    height: 34px;
    line-height: 1;
    font-size: 14px;
    min-width: 75px;
    i {
      font-size: 18px;
      margin-right: 5px;
    }
  }
  .custom-normal-select {
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 5px;
    }
    select {
      max-width: 155px;
    }
  }
  .btn-warning {
    &:hover {
      color: $white;
    }
  }
}
.pricing-summary{
  .title-block {
    .panel-btn{
      border: none;
      height: 31px;
      width: 31px;
      margin-left: 10px;
    }
    .preview-btn{
      height: 36px;
      font-size: 14px;
      line-height: 1;
      img{
        width: auto;
        margin-right: 10px;
      }
    }
  }
  .form-chart-wrapper{
    width: 100%;
    .left-form{
      flex: 0 0 42%;
      max-width: 42%;
      .form-group{
        flex-direction: column;
        label{
          margin-right: 10px;
        }
        .form-control{
          margin-top: auto;
          &[readOnly]{
            border-color: #e1eaea;
          }
        }
      }
      .form-outer{
        margin-bottom: 0;
        &.show{
          display: flex;
        }
      }
    }
    .chart-block{
      flex: 0 0 25%;
      max-width: 25%;
    }
    .right-form{
      flex: 0 0 33%;
      max-width: 33%;
      .form-control{
        &.width-50{
          flex: calc(50% - 15px);
          max-width: calc(50% - 15px);
        }
      }
      label{
        white-space: nowrap;
        margin:0 20px 0 0;
        min-width: 90px;
        text-align: right;
      }
      .bar-icon{
        position: relative;
        top: 2px;
      }
    }
  }
  .tab-radio-btn{
    margin-right: 30px;
    padding-bottom: 0;
    label{
      margin-right: 10px;
    }
  }
}
.highcharts-credits {
  display: none;
}
.checkin-checkout {
  .left-block {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 20px;
    border-right: 1px solid #dee2e6 !important;
    .title-section {
      .calendar-icon {
        max-width: 25px;
        margin-left: 10px;
      }
    }
  }
  .right-block {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 20px;
  }
}
.checkin-checkout-btn-block {
  width: 100%;
  .btn-wrapper {
    flex: 0 0 62%;
    max-width: 62%;
    border-right: 1px solid #dee2e6 !important;
    .btn {
      height: 34px;
      font-size: 12px;
      line-height: 1;
      width: 130px;
      &.disabled,
      &:disabled {
        opacity: 0.65;
      }
      &.btn-success {
        &.disabled,
        &:disabled {
          background-color: $green-shade;
          border-color: $green-shade;
        }
      }
      &.btn-warning {
        height: 34px;
        width: 34px;
        border-radius: 2px;
        padding: 8px;
        i {
          color: $white;
          font-size: 18px;
          height: 18px;
          width: 18px;
        }
        &.disabled,
        &:disabled {
          background-color: $yellow;
          border-color: $yellow;
        }
      }
    }
    .value-block {
      label {
        min-width: 95px;
      }
    }
  }
  .right-block {
    flex: 0 0 38%;
    max-width: 38%;
    padding: 0 0 0 20px;
    .value-block {
      label {
        min-width: 55px;
      }
    }
  }
  .value-block {
    font-size: 14px;
    color: $black-shade-3;
    margin-bottom: 10px;
    display: inline-flex;
    label {
      font-size: 14px;
      color: $black-shade-3;
    }
    .value {
      background: #efefef;
      padding: 3px 20px;
      min-height: 29px;
    }
    .green {
      color: green;
    }
    .green-light {
      color: #3fbf6a;
    }
    .yellow {
      color: #cc931a;
    }
  }
}
.jobStatus {
  width: 100%;
  border-radius: 50rem;
  text-align: center;
  padding: 0 5px;
  color: white;
  font-weight: 600;
}

.custom-accordion {
  .accordion-item {
    border: 1px solid $grey-shade24;
    box-shadow: 0 0 20px 0 $grey-shade24;
    margin-bottom: 5px;
    border-radius: 10px;
    .accordion-header {
      .accordion-button {
        padding: 4px 20px;
        font-size: 16px;
        letter-spacing: normal;
        color: $black-shade-3 !important;
        justify-content: space-between;
        border-radius: 10px;
        &:after {
          display: none;
        }
        &:not(.collapsed) {
          background: $blue-shade-2 !important;
          box-shadow: none;
          color: $white !important;
          border-radius: 10px 10px 0 0;
          i {
            color: $white;
            &:hover {
              background-color: $white;
              color: $blue-shade-2;
            }
          }
        }
        i {
          font-size: 22px;
          padding: 5px;
          color: $blue-shade-2;
          border-radius: 2px;
          transition: all 0.3s;
        }
      }
    }
  }
  .card-header{
    padding: 5px 20px;
  }
  &.secondary-accordion {
    .accordion-item {
      border-radius: 5px;
      .accordion-button {
        padding: 5px 20px;
        min-height: 45px;
        pointer-events: none;
        &:not(.collapsed) {
          background: $white !important;
          color: $black-shade-3 !important;
          border-bottom: 1px solid $grey-shade13;
        }
        .radio-wrapper {
          input {
            pointer-events: initial;
          }
          z-index: 2;
          .form-check input {
            visibility: visible;
          }
        }
      }
    }
  }
}

.co-tag {
  border-radius: 500px;
  background-color: #000000;
  padding: 2px;
  color: #ffffff;
  font-size: 12px;
  font-weight: normal; 
  margin-left: 2px;
}
.inspection-header {
  width: 100%;
}
.inspection-block {
  margin: 0 -15px;
  .input-block-wrapper,
  .comment-block {
    flex: 1;
    padding: 0 15px;
  }
  .form-group {
    label {
      width: 100%;
    }
    .form-control {
      color: $grey-shade1;
      font-weight: 400;
    }
    textarea {
      resize: none;
      height: auto;
    }
  }
  .input-group-text {
    color: $blue-shade-4;
    font-size: 16px;
    font-weight: 600;
    background: $grey-shade27;
    border: 1px solid $grey-shade30;
    width: 45px;
    border-radius: 4px 0 0 4px !important;
  }
  h6 {
    font-size: 14px;
    font-weight: 600;
  }
  .comment-block {
    h6 {
      font-size: 14px;
      font-weight: 400;
    }
    .select-wrapper {
      .form-group {
        flex: 1;
      }
      .btn-primary {
        margin-left: 20px;
        height: 40px;
        font-size: 14px;
      }
    }
  }
  .form-control {
    border-color: $dark-gray-1;
    &:focus {
      border-color: $primary;
    }
  }
  .select-block {
    select {
      width: calc(100% - 46px);
      font-size: 13px;
      color: $grey-shade1;
      font-weight: 700;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
    option {
      font-size: 13px;
      font-weight: 700;
    }
    .option-green {
      background: $green-shade2;
    }
    .option-yellow {
      background: $yellow-shade-4;
    }
    .option-red {
      background: $red-shade-3;
    }
  }
  &.reverse-block {
    border-bottom: 1px solid $grey-shade13;
    .comment-block {
      border-right: 1px solid $grey-shade13;
      padding-bottom: 20px;
    }
  }
  .general-search-wrapper {
    .form-group {
      input {
        &::placeholder {
          font-size: 14px;
        }
      }
      .form-control {
        padding: 6px 120px 6px 20px;
      }
      .btn-primary {
        min-width: auto;
      }
    }
  }
}
.inspection-section-tab {
  .right-block {
    padding-left: 10px;
  }
  .rego-outer {
    flex: 0 0 50%;
    max-width: 50%;
    .rego-detail {
      .search-wrapper {
        padding: 5px 10px !important;
      }
    }
  }
  .date-block-outer {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 0 0 25px;
  }
  .save-block {
    button {
      height: 34px;
      line-height: 1;
      font-size: 14px;
      min-width: 71px;
      margin-right: 5px;
    }
  }
}

.secondary-filepond{
  .filepond--wrapper {
    input[name=files] ~ {
      .filepond--drop-label{
        min-height: 34px !important;
        width: 120px;
        border-radius:20px;
        border: none;
        label{
          background-color: #2b2b2c !important;
          border-color: #2b2b2c !important;
          border-radius:20px;
          padding: 6px 12px;
          color: #fff;
          font-size: 14px;
          height: auto;
          display: flex !important;
          position: relative;
          span{
            line-height: 1;
          }
          span,i{
            opacity: 1;
          }
          i{
            margin-left: 5px;
          }
          &:after{
            display: none;
          }
        }
      }
    }
    .filepond--drop-label{
      label{
        &:after{
          content: "+";
          position: absolute;
          font-size: 40px;
          color: $primary;
          font-family: 'LineAwesome';
          display: inline-block;
        }
        span,i{
          opacity: 0;
        }
      }
    }
  }
  &.browse-option{
    .filepond--wrapper {
      input[name=files] ~ {
        .filepond--drop-label{
          height: 42px;
          width: 115px;
          border-radius: 0;
          label{
            height: 42px;
            line-height: 1;
            width: 115px;
            background-color: $blue-shade-6 !important;
            border: none;
            border-radius: 0;
            padding: 5px;
            border-radius: 4px;
            font-size: 14px;
            font-family: 'Open Sans', sans-serif;
            i{
              font-size: 16px;
              margin: 0 5px 0 0;
            }
          }
        }
      }
      .filepond--list-scroller{
        margin-left:100px;
      }
      .filepond--drop-label {
        label{
          font-size: 0;
        }
      }
      .filepond--file-action-button{
        background-color: $black;
      }
    }
    .search-block{
      display: flex;
    }
  }
}

.filepond--wrapper{
  .filepond--panel-root{
    background: $white !important;
  }
  p,span{
    font-family: 'Open Sans', sans-serif;
  }
  .filepond--root .filepond--credits[style]{
    display: none;
  }
  .filepond--drop-label {
    min-height: 0px !important;
    background: $white !important;
    border: none;
    border-radius: 0;
    position: absolute;
    margin-bottom: 10px;
    opacity: 1 !important;
    visibility: visible !important;
    transform: none !important;
      .la-upload{
        font-size: 45px;
        height: 45px;
        line-height: 1;
        color: $blue-shade-4 !important;
      }
      p{
       display: none;
      }
      label{
        position: absolute;
        left: 0;
        top: 15px;
        border:2px dashed rgba(0, 0, 0, 0.3);
        height: 100px;
        width: 100px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
  }
  .filepond--list-scroller {
    margin-left: 100px;
  }
  input[name="files"] ~ .filepond--drop-label{
    min-height: 165px !important;
    background: $white !important;
    border:2px dashed rgba(0, 0, 0, 0.3);
    border-radius: 0;
    position: relative;
    margin-bottom: 10px;
    .la-upload{
      color: $blue-shade-4 !important;
      font-size: 40px;
      height: 40px;
      line-height: 1;
    }
    p{
      font-size: 20px;
      color: $grey-shade26;
      font-weight: 700;
      display: block;
      width: auto;
    }
    label {
      position: relative;
      left: auto;
      top: auto;
      border: none;
      display: block !important;
      width: auto;
    }
  }
  .filepond--list.filepond--list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    .filepond--item {
      position: relative;
      height: 100px !important;
      width: 100px !important;
      transform: none !important;
    }
  }
  .filepond--file-info {
    .filepond--file-info-sub {
      font-size: 18px;
      position: absolute;
      top: 30px;
      left: -18px;
    }
  }
}


.top-block a {
  background-color: #11cfed;
  border-color: #11cfed;
  color: #ffffff;

  &:hover {
    background-color: #13b8d1;
    border-color: #13b8d1;
    color: #ffffff;
  }
}

// Nirav Css Start
.font-size-24 {
  font-size: 24px;
}
.text-primary {
  color: $primary !important;
}
.card-header {
  font-size: 16px;
  padding-top: 15px;
  padding-bottom: 15px;
  letter-spacing: 1px;
  color: #232425;
  text-transform: uppercase;
  background: transparent;
}
.btn {
  &.btn-block {
    display: block;
    width: 100%;
    text-align: center;
  }
  &.btn-rounded {
    border-radius: 24px;
  }
  &.width-100{
    width: 100%;
  }
}
.settings-navigation-list a.setting-link {
  position: relative;
}
.settings-navigation-list a.setting-link::after {
  content: "";
  position: absolute;
  left: -20px;
  top: 0;
  height: 100%;
  width: 3px;
  background-color: #000;
  opacity: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.settings-navigation-list a.setting-link:hover:after {
  left: -15px;
  opacity: 1;
}
.form-group textarea.form-control {
  height: auto;
  font-weight: 400;
  &::placeholder {
    opacity: 0.7;
  }
}
.table-header .profile-tabs .tab-content {
  background: transparent;
  padding: 0;
}
.chat-bubble {
  border-radius: 15px;
  background: #e5e5e5;
  padding: 20px;
  font-weight: 600;
  position: relative;
  margin-right: 15px;
}

.chat-bubble::after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-left: 15px solid #e5e5e5;
  border-right: 15px solid transparent;
  border-top: 15px solid #e5e5e5;
  border-bottom: 15px solid transparent;
  right: -16px;
  top: 0;
}

.chat-bubble.bubble-primary {
  background-color: #52d9ee;
  color: #fff;
}

.chat-bubble.bubble-primary::after {
  border-color: #52d9ee transparent transparent #52d9ee;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}
.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-30 {
  font-size: 30px !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
input,
select,
textarea {
  font-family: "Source Sans Pro", sans-serif;
  color: #767676;
  &::placeholder{
    font-weight: 400;
    opacity: 1;
  }
}
.numberCircle {
  background: #000000;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.8em;
  margin-right: 5px;
  text-align: center;
  width: 1.8em;
  font-size: 16px;
}
.width-auto {
  width: auto !important;
}
.color-thumb {
  padding: 0;
  button,
  span {
    display: block;
    border: 0;
    background-color: transparent;
    width: 40px;
    height: 100%;
  }
}
.img-circle {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.time-input-no-label.time-input span {
  top: 11px;
}
// Nirav Css End

.msgPreview {
  background-color: #11cfed;
  border-color: #11cfed;
  color: #ffffff;
  border-radius: 30px;
  border: 0;
  padding: 7px 14px;
}
.msgPreview:hover {
  background-color: #13b8d1;
  border-color: #13b8d1;
  color: #ffffff;
}

.inspection-sent-title {
  background: $blue-shade-4;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  p {
    margin: 0;
    font-size: 24px;
    line-height: 1;
    color: $white;
  }
  .rounded-btn {
    min-height: 34px;
    line-height: 1;
    font-size: 14px;
  }
}
.technician-detail-block {
  margin: 0 -10px;
  padding: 10px 0;
  .block-wrapper {
    flex: 0 0 calc(100% / 6);
    max-width: calc(100% / 6);
    padding: 0 10px;
    i {
      margin-right: 5px;
      height: 20px;
      line-height: 1;
      width: 20px;
      font-style: normal;
    }
    .content {
      max-width: calc(100% - 30px);
      strong {
        margin-bottom: 5px;
        display: block;
        line-height: 1.4;
      }
    }
  }
}
.timer-block {
  background-color: $grey-shade2;
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
  color: $white;
  padding: 5px 10px;
  border-radius: 15px;
}
.sent-inspection-block {
  margin: 0 -15px;
  .heading-block {
    box-shadow: 0 0 22px 0 $dark-gray-3;
    border-radius: 5px;
    background: $blue-shade-4;
    padding: 8px 20px;
    color: $white;
    font-size: 20px;
    line-height: 1.1;
    font-weight: 500;
    width: 100%;
    margin-bottom: 30px;
    font-family: "Source Sans Pro", sans-serif;
  }
  .left-block,
  .right-block {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 15px;
  }
  .left-block {
    border-right: 1px solid $dark-gray-6;
    .content-wrapper {
      .content-block {
        margin-bottom: 10px;
      }
      .label {
        font-size: 16px;
        font-weight: 600;
        margin-right: 10px;
      }
      .value {
        margin-right: 20px;
      }
    }
    .comment-section {
      margin: 0;
      .label {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        margin-bottom: 0;
      }
    }
    .accordion-header {
      .accordion-button {
        text-transform: uppercase;
        font-family: "Source Sans Pro", sans-serif;
      }
      .radio-wrapper {
        font-family: "Open Sans", sans-serif;
      }
    }
  }
  .accordion-item {
    .accordion-button {
      .radio-wrapper {
        input {
          pointer-events: none !important;
        }
      }
    }
    .accordion-header {
      .accordion-button:not(.collapsed) {
        .la-angle-down {
          transform: rotate(180deg);
        }
      }
    }
  }
  .btn-block {
    padding-top: 30px;
    border-top: 1px solid $grey-shade13;
    .btn {
      height: 45px;
      line-height: 1;
    }
  }
  .custom-accordion {
    &.secondary-accordion {
      .accordion-item {
        .accordion-button {
          pointer-events: initial;
        }
      }
    }
  }
  .total-block {
    border-top: 1px solid $grey-shade10;
    padding: 10px;
    .title {
      flex: 0 0 50%;
      max-width: 50%;
    }
    span {
      min-width: 22%;
    }
  }
}
.quote-table {
  .react-bootstrap-table {
    tr {
      td {
        padding: 16px;
      }
      &:last-child {
        border: none;
      }
    }
    thead {
      tr {
        border-bottom: 1px solid $grey-shade10 !important;
      }
    }
  }
  .status-block {
    height: 30px;
    width: 30px;
    // background: $yellow;
    border-radius: 100%;
    color: $white;
  }
}
.red-bg {
  background-color: #e64b3b;
}
.yellow-bg {
  background-color: #e6a821;
}
.custom-label-radio {
  font-family: "Open Sans", sans-serif;
  .form-check-input {
    background-color: transparent;
    margin: 0;
    padding: 9px 14px;
    border-radius: 0;
    font-weight: 400;
    color: #333;
    font-size: 14px;
    line-height: 14px;
    width: 100%;
    border: 1px solid $blue-shade-7;
    height: auto;
    cursor: pointer;
    &.active {
      background: $blue-shade-7;
      color: $white;
    }
    &:first-child {
      border-radius: 17px 0 0 17px;
    }
    &:last-child {
      border-radius: 0 17px 17px 0;
    }
  }
}
.internal-note-section {
  .filter-section {
    padding: 15px;
    border-bottom: 1px solid $blue-shade-10;
    .select-block {
      margin-right: 25px;
      .custom-select {
        width: 245px;
      }
    }
    .grey-btn {
      margin-right: 25px;
      i {
        margin-left: 5px;
      }
    }
    .btn-primary {
      margin-right: 25px;
      min-width: 71px;
    }
  }
}
.notes-outer {
  padding: 15px;
  .notes-block {
    display: flex;
    margin-bottom: 20px;
    .profile-block {
      height: 60px;
      width: 60px;
      background-color: $blue-shade-13;
      border-radius: 100%;
      color: $white;
      font-size: 22px;
      line-height: 26px;
      font-weight: 600;
    }
    .chat-content {
      padding: 5px 20px;
      background-color: $grey-shade24;
      flex: 1;
      margin-left: 15px;
      border-radius: 15px;
      position: relative;
      &::after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        right: auto;
        left: -15px;
        top: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid $grey-shade24;
        border-top: 15px solid $grey-shade24;
        border-bottom: 15px solid transparent;
      }
      .msg-wrapper {
        flex: 1;
        font-size: 14px;
        color: $black-shade-3;
        font-weight: 600;
        .top-block {
          border-bottom: 1px solid $dark-gray-6;
          margin-bottom: 10px;
          .image-preview {
            a {
              margin: 0 10px 10px 0;
              background: transparent !important;
            }
            img {
              height: 70px;
              width: 70px;
            }
          }
          p {
            margin-bottom: 10px;
          }
        }
        .date-time-section {
          .time {
            padding-left: 14px;
            margin-left: 14px;
            border-left: 1px solid $dark-gray-7;
          }
        }
      }
      .btn-danger {
        margin-left: 15px;
        font-size: 14px;
        min-width: 72px;
      }
    }
  }
}
.secondary-filepond {
  .filepond--wrapper {
    input[name="files"] ~ {
      .filepond--drop-label {
        min-height: 34px !important;
        width: 120px;
        border-radius: 20px;
        border: none;
        label {
          background-color: #2b2b2c !important;
          border-color: #2b2b2c !important;
          border-radius: 20px;
          padding: 6px 12px;
          color: #fff;
          font-size: 14px;
          height: auto;
          display: flex !important;
          position: relative;
          span {
            line-height: 1;
          }
          span,
          i {
            opacity: 1;
          }
          i {
            margin-left: 5px;
          }
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
.filepond--wrapper{
  .filepond--panel-root{
    background: $white !important;
  }
  p,span{
    font-family: 'Open Sans', sans-serif;
  }
  .filepond--root .filepond--credits[style]{
    display: none;
  }
  .filepond--drop-label{
    min-height: 0px !important;
    background: $white !important;
    border:none;
    border-radius: 0;
    position: absolute;
    margin-bottom: 10px;
    opacity: 1 !important;
    visibility: visible !important;
    transform: none !important;
      .la-upload{
        font-size: 45px;
        height: 45px;
        line-height: 1;
        color: $blue-shade-4 !important;
      }
      p{
       display: none;
      }
      label{
        position: absolute;
        left: 0;
        top: 15px;
        border:2px dashed rgba(0, 0, 0, 0.3);
        height: 100px;
        width: 100px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
  .filepond--list-scroller{
    margin-left: 100px;
  }
  input[name="files"] ~ .filepond--drop-label{
    min-height: 165px !important;
    background: $white !important;
    border:2px dashed rgba(0, 0, 0, 0.3);
    border-radius: 0;
    position: relative;
    margin-bottom: 10px;
    .la-upload{
      color: $blue-shade-4 !important;
      font-size: 60px;
      height: 60px;
      line-height: 1;
    }
    .filepond--drop-label{
      label{
        &:after{
          content: "+";
          position: absolute;
          font-size: 40px;
          color: $primary;
          font-family: "LineAwesome";
          display: inline-block;
        }
        span,
        i {
          opacity: 0;
        }
      }
      label{
        position: absolute;
        left: 0;
        top: 15px;
        border:2px dashed rgba(0, 0, 0, 0.3);
        height: 100px;
        width: 100px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
  .filepond--list-scroller{
    margin-left: 100px;
  }
  input[name="files"] ~ .filepond--drop-label{
    min-height: 165px !important;
    background: $white !important;
    border:2px dashed rgba(0, 0, 0, 0.3);
    border-radius: 0;
    position: relative;
    margin-bottom: 10px;
    .la-upload{
      color: $blue-shade-4 !important;
      font-size: 60px;
      height: 60px;
      line-height: 1;
    }
    p{
      font-size: 20px;
      color: $grey-shade26;
      font-weight: 700;
      display: block;
      width: auto;
    }
    label{
      position: relative;
      left: auto;
      top: auto;
      border: none;
      display: block !important;
      width: auto;
    }
  }
  .filepond--list.filepond--list{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    .filepond--item{
      position: relative;
      height: 100px !important;
      width: 100px !important;
      transform: none !important;
    }


  &.browse-option{
    .filepond--wrapper {
      input[name=files] ~ {
        .filepond--drop-label{
          height: 42px;
          width: 115px;
          border-radius: 0;
          label{
            height: 42px;
            line-height: 1;
            width: 115px;
            background-color: $blue-shade-6 !important;
            border: none;
            border-radius: 0;
            padding: 5px;
            border-radius: 4px;
            font-size: 14px;
            font-family: 'Open Sans', sans-serif;
            i{
              font-size: 16px;
              margin: 0 5px 0 0;
            }
          }
        }
      }
      .filepond--list-scroller {
        margin-left: 100px;
      }
      .filepond--drop-label {
        label {
          font-size: 0;
        }
      }
      .filepond--file-action-button {
        background-color: $black;
      }
    }
    .search-block {
      display: flex;
    }
  }
}
  .filepond--file-info {
    .filepond--file-info-sub{
      font-size: 18px;
      position: absolute;
      top: 30px;
      left: -18px;
    }
  }


.job-detail-tab {
  .accordion-body {
    padding: 20px 20px;
  }
  .accordion-item {
    border-radius: 10px;
    box-shadow: 0 0 20px 0 #e5e5e5;
    border: 1px solid #e5e5e5;
    .accordion-button {
      border-radius: 10px 10px 0 0;
      &.collapsed {
        border-radius: 10px;
      }
    }
  }
}
.attachment-tab-section {
  .panel-heading {
    display: block;
    border-bottom: 1px solid $grey-shade6;
    width: 100%;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
  .create-job-form {
    .left-block {
      .select-block {
        &.width-33 {
          flex: calc(33.33% - 10px);
          max-width: calc(33.33% - 10px);
        }
      }
      &:last-child{
        border: none !important;
      }
    }
  }
}

.smsText {
  background: #5fd9ee;
  color: white;
  font-size: x-small;
  font-weight: 600;
  padding: 2px 5px;
  // margin-left: -7px;
  margin-right: 9px;
}

.date-block {
  width: 100%;
  .form-group {
    width: 50%;
    .form-control {
      font-size: 14px;
      font-family: "Source Sans Pro", sans-serif;
      font-weight: 400;
    }
  }
}
.search-section {
  align-items: flex-start;
}

.attach-invoice-table {
  width: 100%;
  .table-bordered {
    tbody {
      tr:hover {
        background-color: $white;
      }
    }
  }
  .pdf-wrapper {
    img {
      max-width: 50px;
    }
  }
  .form-group {
    .form-control {
      max-width: 90%;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .download-wrapper {
    img {
      max-width: 30px;
      margin-right: 10px;
    }
  }
}
.page-heading-block {
  background-color: $blue-shade-4;
}
.user-profile {
  .form-group {
    .form-control {
      font-weight: 400;
    }
  }
}
.table-search-block {
  .form-control {
    height: 35px;
    margin-left: 10px;
    border-radius: 17px;
    font-weight: 400;
  }
}

.sms-email-wrapper {
  display: flex;
  align-items: center;
  .msg-title {
    margin-right: 20px;
    display: flex;
    align-items: center;
    i {
      color: #1cc9e4;
      font-size: 20px;
      margin-right: 10px;
    }
  }
  .grey-btn {
    height: 34px;
    line-height: 1;
    font-size: 14px;
    min-width: 150px;
    margin-right: 10px;
  }
}
.invoice-wrapper {
  .invoice-heading {
    background: $white;
    padding: 20px;
    border-radius: 0 0 10px 10px;
    margin-bottom: 20px;
    img{
      max-width: 23px;
    }
    h3 {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 20px;
      font-weight: 500;
      color: $black-shade-2;
      letter-spacing: 1px;
      text-transform: uppercase;
      .quote-job-number {
        color: #f00;
        font-size: 26px;
        font-style: italic;
        font-weight: 400;
      }
    }
    .btn-wrapper {
      .btn {
        font-size: 14px;
        margin-left: 5px;
      }
    }
  }
  .invoice-total-btn{
    min-width: 120px;
    font-size: 14px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 500;
  }
  .invoice-content {
    padding: 20px;
    background-color: $white;
    border-radius: 0 0 10px 10px;
    margin-bottom: 20px;
    .basic-multi-select {
      .select__control {
        min-height: 42px;
      }
    }
    .select-wrapper {
      .form-group {
        margin: 0;
        max-width: 100%;
      }
    }
  }
}
.quote-preview {
  padding: 15px;
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0 0 22px 0 $dark-gray-3;
  .left-block {
    flex: 0 0 30%;
    max-width: 30%;
    h2 {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 30px;
      color: $dark-gray-8;
      margin-bottom: 10px;
    }
    h4 {
      font-size: 18px;
      color: $dark-gray-8;
      margin-bottom: 10px;
    }
  }
  .tab-radio-btn {
    &.yes-no-radio {
      .form-check-label {
        min-width: auto;
      }
    }
  }
  .logo-block {
    max-width: 150px;
  }
  .invoice-title{
    letter-spacing: 25px;
    font-weight: 500;
    font-size: 36px;
    color:$blue-shade-4;
    font-family: "Source Sans Pro", sans-serif;
  }
}
.schedular-blocks {
  border: 1px solid $dark-gray-6;
  border-radius: 4px;
  align-items: flex-start;
  .form-check {
    position: relative;
    padding: 0;
    cursor: pointer;
    margin: 0 !important;
    input {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      margin: 0;
      opacity: 0;
      visibility: hidden;
      border: none;
      &:checked + .form-check-label {
        background-color: $blue-shade-10;
        color: $white;
      }
    }
  }
  .scheduler-person-list {
    border-right: 1px solid $dark-gray-6;
    width: 200px;
    li {
      margin-right: 10px;
    }
    .form-check-label {
      display: inline-flex;
      min-width: 100%;
      min-height: 40px;
      padding: 10px;
      align-items: center;
      border-bottom: 1px solid $dark-gray-6;
      color: $black-shade-3;
      cursor: pointer;
      font-size: 14px;
      font-family: "Open Sans", sans-serif;
    }
  }
  .schedular-list {
    flex: 1;
    li {
      width: calc(25% - 10px);
      margin: 5px;
    }
    .form-check-label {
      display: inline-flex;
      min-width: 100%;
      min-height: 40px;
      align-items: center;
      justify-content: center;
      border: 1px solid $dark-gray-6;
      color: $black-shade-3;
      cursor: pointer;
      font-size: 14px;
      font-family: "Open Sans", sans-serif;
    }
  }
}
.small-accordion {
  .accordion-item {
    box-shadow: 0 0 20px 0 $grey-shade24;
    border: 1px solid $grey-shade24;
    margin-bottom: 20px;
    border-radius: 10px;
    .accordion-button {
      padding: 3px 20px;
      font-size: 16px;
      font-family: "Source Sans Pro", sans-serif;
      letter-spacing: normal;
      color: $black-shade-3 !important;
      justify-content: space-between;
      border-radius: 10px;
      &.collapsed {
        border-radius: 10px;
      }
      &:after {
        display: none;
      }
      i {
        font-size: 16px;
        padding: 5px;
        color: $blue-shade-2;
        border-radius: 2px;
        transition: all 0.3s;
      }
      &:not(.collapsed) {
        border-bottom: 1px solid $grey-shade23;
        border-radius: 10px 10px 0 0;
        .la-angle-down {
          transform: rotate(180deg);
        }
      }
    }
  }
}
.customer-assets-block {
  .heading-block {
    border-bottom: 1px solid $grey-shade23;
    padding-bottom: 12px;
    margin-bottom: 15px;
  }
  .block-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 15px;
    &.left-block {
      border-right: 1px solid $grey-shade13;
    }
  }
  .detail-outer {
    margin: 0 -15px;
    .detail-wrapper {
      border-left: 1px solid $grey-shade13;
      &:first-child {
        border: none;
      }
      .detail-block {
        margin-bottom: 10px;
        padding: 0 15px;
        label {
          margin-right: 30px;
          font-size: 12px;
          color: $grey-shade2;
          i {
            margin-right: 5px;
          }
        }
        span {
          font-size: 12px;
          margin-right: 10px;
          color: $grey-shade2;
        }
      }
    }
  }
  .right-block {
    .detail-wrapper {
      flex: 0 0 100%;
      max-width: 100%;
      .detail-block {
        label{
          margin-right: 10px;
        }
      }
    }
  }
  .width-50 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.bar-icon {
  border: none;
  background-color: transparent;
  outline: none;
  &:hover {
    .bar1,
    .bar2,
    .bar3 {
      background-color: $blue-shade-6;
    }
  }
  .bar1,
  .bar2,
  .bar3 {
    width: 17px;
    height: 2px;
    background-color: $grey-shade2;
    margin: 2px 0;
    transition: 0.4s;
  }
  .bar2 {
    width: 13px;
    margin-left: 2px;
  }
  .bar3 {
    width: 8px;
    margin-left: 4px;
  }
}

.discount-block {
  flex-direction: column;
}
.moveToLost-icon i{
  background-color: #52d9ee;
  display: inline;
  padding: 3px 5px;
  font-weight: 400;
  line-height: 1;
  font-size: 14px !important;
  color: #fff;
  text-align: center;
  font-weight: 400;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.user-profile-image{
  margin-left: 130px;
  height: 100px;
  width: 100px;
}
.custom-dropdown-list{
  .dropdown-toggle{
    height: 34px;
    line-height: 1;
    font-size: 14px;
    border-radius: 20px;
  }
  .dropdown-menu{
    padding: 5px 0;
    background: #fff;
    border: 1px solid rgba(0,0,0,.15);
    a{
        text-align: left;
        display: block;
        padding: 10px 20px;
        right: 0;
        color: $grey-shade2;
        margin-right: 10px;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: "Open Sans", sans-serif;
        &:hover{
            background-color: $blue-shade-4;
            color: $white;
        }
    }
  }
}
.dashboard-chart{
  .dropdown-btn{
    &.show{
        button{
            background-color: rgba(0, 0, 0, 0.07) !important;
        }
    }
  }
  .custom-dropdown-list {
    .dropdown-menu{
      left: auto !important;
      right: 0 !important;
      a{
        font-size: 14px;
        color: $grey-shade2;
        &:hover{
          background-color: $blue-shade-4;
          color: $white;
      }        
      }
    }
  }
}
.tooltip-datepicker{
  position: relative;
  img{
    height: 23px;
    width: 23px;
  }
  .ant-picker{
    position: absolute;
    inset: 0px;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
    padding: 0;
  }
  .ant-picker-clear{
    display: none;
  }
}
.card{
  box-shadow: 0 0 22px 0 $dark-gray-3;
  border-radius: 5px;
}
.dashboard-table{
  span,p,a{
    font-family: "Open Sans", sans-serif;
    color: $black-shade-3;
    font-weight: 500;
    font-size:13px;
  }
  a{
    color: $blue;
  }
  thead{
    display: none;
  }
  td{
    padding: 5px 15px !important;
  }
  .content-block{
    max-width: calc(100% - 50px);
    p{
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  tbody{
    tr{
      &:first-child{
        border-top: none;
      }
    }
  }
  .custom-switch {
    .form-check-input {
      height: 28px;
      width: 50px;
      background-size: 26px;
      background-color: $dark-gray-10;
      border-color: $dark-gray-10 !important;
    }
  }
  .rounded-pill{
    height: 26px;
    display: inline-flex;
    min-width: 53px;
    line-height: 1;
  }
}
.custom-slider {
  .carousel-inner {
    height: 100%;
    .carousel-item{
      background-color: #000;
      img{
        object-fit: cover;
      }
      &::after{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(68, 68, 68, 0.7);
        z-index: 1;
      }
    }
  }
  .carousel-control-prev-icon,.carousel-control-next-icon{
    height: 16px;
    width: 16px;
  }
  .carousel-indicators{
    display: none;
  }
  .carousel-caption{
    z-index: 2;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
    .view-all{
      color: $white;
      border: 1px solid rgba(255,255,255,0.20);
      padding: 5px 15px;
      text-align: center;
      border-radius: 25px;
      -webkit-border-radius: 25px;
      margin-bottom: 18px;
      display: inline-block;
      text-transform: uppercase;
      font-size: 12px;
      margin-top: 20px;
      background: transparent;
      &:hover{
        background: rgba(255,255,255,0.20);
        color: $black;
      }
    }
    h3,.date{
      text-align: center;
      font-size: 12px;
      margin: 5px;
      font-weight: normal;
      text-transform: uppercase;
    }
    h3{
      color: $green-shade1;
    }
    .title{
      text-align: center;
      font-size: 14px;
      margin: 40px 20px;
      line-height: 20px;
      font-weight: normal;
      font-style: italic;
    }
  }
  .carousel-control-prev, .carousel-control-next{
    height: 20px;
    width: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
  .carousel-control-next{
    right: 10px;
  }
  .carousel-control-prev{
    left:10px;
    border: 1px solid rgba(0, 0, 0, 0.15);

    a {
      text-align: left;
      display: block;
      padding: 10px 20px;
      right: 0;
      color: $grey-shade2;
      margin-right: 10px;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-family: "Open Sans", sans-serif;

      &:hover {
        background-color: $blue-shade-4;
        color: $white;
      }
    }
  }
}

.input-height-35 {
  height: 35px !important;
}
.justify-space-evenly {
  justify-content: space-evenly !important;
}
.accordion-panel {
  box-shadow: 0 0 22px 0 $dark-gray-3;
  font-size: 18px;
  letter-spacing: 1px;
  color: $black-shade-2;
  border-radius: 5px;
  .heading {
    padding: 5px 10px;
  }
  h4 {
    margin: 5px 0;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 18px;
    letter-spacing: 1px;
    color: $black-shade-2;
    font-weight: 500;
    text-transform: uppercase;
  }
  &.has-btn {
    border-bottom: 1px solid $grey-shade6;
  }
  .status {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 5px 20px;
    border-radius: 18px;
    &.warning {
      background-color: $yellow;
      color: $white;
    }
    &.success {
      background-color: $blue-shade14;
      color: $white;
    }
    &.danger {
      background-color: $red-shade-2;
      color: $white;
    }
  }
  .accordion-content {
    border-top: 1px solid $grey-shade6;
    padding: 15px;
    p {
      font-size: 14px;
      letter-spacing: 0;
    }
  }
  .value{
    font-size: 14px;;
  }
}
.light-box-wrapper {
  > div {
    z-index: 9;
    background-color: rgb(0, 0, 0, 0.9) !important;
  }
  img {
    height: auto;
    width: auto;
  }
}
.lightbox-btn {
  border: 2px dashed $dark-gray-6;
  padding: 0;
  height: 100px;
  width: 100px;
  margin: 0 0 10px 10px;
  img {
    object-fit: cover;
  }
}

.selection-cell-header,
.selection-cell {
  display: inline-block !important;
}


.grid {
  display: grid;
}

.gap-custom {
  &-5 {
    gap: 5px;
  }

  &-10 {
    gap: 10px;
  }

  &-12 {
    gap: 12px;
  }

  &-16 {
    gap: 16px;
  }

  &-24 {
    gap: 24px;
  }

  &-32 {
    gap: 32px;
  }
}

.center-dialog {
  height: 100%;
  display: flex;
  align-items: center;
}

/* Dropdown */

.dropdown {
  display: inline-block;
  position: relative;
}

.dd-button {
  display: inline-block;
  padding: 10px 30px 10px 20px;
  background-color: #13b8d1;
  cursor: pointer;
  white-space: nowrap;
}

.dd-button:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
}

.dd-input {
  display: none;
}

.dd-menu {
  position: absolute;
  top: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0;
  margin: 2px 0 0 -20px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  list-style-type: none;
}

.dd-input + .dd-menu {
  display: none;
}

.dd-input:checked + .dd-menu {
  display: block;
  z-index: 3;
}

.dd-menu li {
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;
}

.dd-menu li:hover {
  background-color: #13b8d1;
  color: #fff;

  & > * {
    background-color: #13b8d1 !important;
    color: #fff !important;
  }
}

.print {
  @media print {
    display: block;
  }
  @media screen {
    display: none;
  }
}

.App {
  @media print {
    display: none;
  }
  @media screen {
    display: block;
  }
}

.mr {
  &-half {
    margin-right: 0.5rem;
  }
  &-1 {
    margin-right: 1rem;
  }
  &-2 {
    margin-right: 1.5rem;
  }
  &-3 {
    margin-right: 2rem;
  }
}

.ml {
  &-half {
    margin-left: 0.5rem;
  }
  &-1 {
    margin-left: 1rem;
  }
  &-2 {
    margin-left: 1.5rem;
  }
  &-3 {
    margin-left: 2rem;
  }
}

.font-bold{
  font-weight: bold;
}
.no-resize{
  resize: none;
}


.helvetica{
  font-family: 'Helvetica', sans-serif;
}
.message-tab-content{
  .table-search-block{
      margin-top:-50px;
   }
}
.disable-upload{
  .filepond--action-process-item{
    display: none;
  }
}