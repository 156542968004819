/* form elements css */
/* buttons and link css */
/* form css */
/* custom check */
a {
	text-decoration: none;
}
* {
	box-sizing: border-box;
	&:focus {
		box-shadow: none !important;
	}
}
ul{
	list-style-type: none;
	padding: 0;
	margin: 0;
}
img {
	height: 100%;
	width: 100%;
}
.btn-check {
	&:focus+.btn {
		box-shadow: none !important;
	}
}
.btn {
	&:focus {
		box-shadow: none !important;
	}
	&:active {
		box-shadow: none !important;
	}
}
.container {
	max-width: calc(100% - 30px);
}
textarea{
	min-height: 62px !important;
}
.form-control {
	&:focus {
		border-color: $primary;
		box-shadow: none;
	}
}
.form-group {
	.text-right {
		text-align: right;
		display: block;
	}
	.form-control {
		height: 42px;
		color: $grey-shade1;
		font-size: 14px;
		font-weight: 600;
		&::placeholder {
			opacity: 0.5;
		}
	}
	label{
		margin-bottom: 5px;
		color: $black-shade-3;
    	font-weight: 400;
	}
}
.form-label {
	color: $grey;
	font-weight: 600;
	font-size: 14px;
}
.primary-btn {
	background-color: $blue-shade-6;
	border-color: $blue-shade-6;
	height: 42px;
	font-size: 15px;
	font-weight: 700;
	transition: all 0.3s;
	transform: scale(0.98);
	text-transform: uppercase;
	padding: 10px 50px;
}

.btn-primary{
	background-color: $blue-shade-6;
	border-color: $blue-shade-6;
	&.rounded-btn{
		border-radius: 30px;
	}
	&.disabled, &:disabled{
		background-color: $blue-shade-6 !important;
		border-color: $blue-shade-6 !important;
	}
}
.rounded-btn{
	border-radius: 30px;
}
.btn-outline{
	border: 1px solid $grey-shade11;
	color: $grey-shade2;
	&:hover{
		background-color: $grey-shade11;
		border-color: transparent;
		color: $grey-shade2;
	}
	&.outline-black{
		border: 1px solid $grey-shade2;
		color: $grey-shade2;
		border-radius: 2px;
		&:hover{
			background-color: $grey-shade2;
			color: $white;
		}
	}
}
.btn-outline-primary{
	border: 1px solid $blue-shade-6;
	color: $blue-shade-6;
	&:hover{
		background-color: $blue-shade-7;
		border-color: $blue-shade-7;
		color: $white;
	}
}
.btn-primary {
	&:hover {
		background-color: $primary-hover;
		border-color: $primary-hover;
		transform: scale(1);
	}
	&:focus {
		background-color: $primary;
		border-color: $primary;
		transform: scale(1);
	}
}

.btn-warning{
	background: $yellow;
	padding: 5px 10px;
	height: 26px;
	border-radius: 15px;
	display: flex;
	align-items: center;
	color: $white;
	letter-spacing: -0.5px;
}
.btn-secondary{
	background-color: $grey-shade18;
	border-color: $grey-shade18;
}
.btn-success{
	background-color: $green-shade;
	border-color: $green-shade;
	&:hover{
		background-color: $green-shade4;
		border-color: $green-shade4;
	}
}
.btn-danger{
	background-color: $red-shade-2;
	border-color: $red-shade-2;
	&:hover{
	  background-color: $red-shade-5;
	  border-color: $red-shade-5;
	}
  }
.btn-default{
	background-color: $grey-shade11 !important;
	border-color: $grey-shade11 !important;
	color: $grey-shade15;
	&:hover{
		color: $grey-shade15;
	}
}
.btn-black{
	background-color: $black-shade !important;
	border-color: $black-shade !important;
	color: $white;
	&:hover{
		background-color: $black !important;
		border-color: $black !important;
		color:$white;
	}
}
.btn-white{
	background-color: $white;
	border-color: $white;
	color: $grey-shade15;
	&:hover{
		background-color: #e2e2e2;
		border-color: #e2e2e2;
		color: $grey-shade15;
	}
}

.primary-link {
	color: $primary;
	padding: 5px;
	font-weight: 500;
	font-size: 14px;
	border-radius: 3px;
	transition: all 0.3s;
	&:hover {
		color: $white;
		background-color: $primary;
	}
}
.secondary-link {
	color: $grey;
	font-weight: 500;
	font-size: 14px;
	&:hover {
		color: $blue-shade;
	}
}
.form-outer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.form-group {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.form-group.width-50 {
		flex: 0 0 calc(50% - 5px);
		max-width: calc(50% - 5px);
	}
	.width-33{
		flex: calc(33.33% - 15px);
		max-width: calc(33.33% - 15px);
	}
	.width-25{
		flex: calc(25% - 15px);
		max-width: calc(25% - 15px);
	}
	&.form-align-start{
		margin: 0 -15px 20px;
        justify-content: flex-start;
		.form-group{
			margin: 0 15px;
			max-width: calc(100% - 30px);
		}
        .width-25{
            flex: calc(25% - 30px);
            max-width: calc(25% - 30px);
            margin: 0 15px;
        }
        .width-33{
            flex: calc(33.33% - 30px);
            max-width: calc(33.33% - 30px);
            margin: 0 15px;
        }
        .width-50{
            flex: calc(50% - 30px);
            max-width: calc(50% - 30px);
            margin: 0 15px;
        }
	}
	.mobile-wrapper{
		display: flex;
		.mobile-code-input{
			max-width: 70px;
			input {
				background-color: #eee;
				pointer-events:none;
			}
		}
		.form-group{
			flex: 1;
			margin: 0;
		}
	}
}
.custom-check {
	position: relative;
	display: block;
	padding-left: 30px;
	cursor: pointer;
	input {
		opacity: 0;
		&:checked {
			+ {
				label {
					.check {
						background-color: $primary;
						border-color: $primary;
						&::after {
							opacity: 1;
						}
					}
				}
			}
		}
		&:focus{
			+ {
				label {
					.check {
						box-shadow:inset 0 0 0 20px rgb(15, 179, 204), 0 0 0 2px rgb(255, 255, 255), 0 0 0 3px rgb(15, 179, 204);
					}
				}
			}
		}
	}
	.check {
		content: '';
		position: absolute;
		height: 20px;
		width: 20px;
		border: 1px solid $primary;
		background-color: $white;
		left: 0;
		top: 0;
		transition: all 0.3s;
		&:after {
			content: '';
			position: absolute;
			height: 8px;
			width: 12px;
			border: solid $white;
			border-width: 0 0 3px 3px;
			transform: rotate(-45deg);
			top: 3px;
			left: 3px;
			opacity: 0;
			transition: all 0.3s;
		}
	}
	.form-check-label {
		color: $grey-shade2 !important;
		font-size: 14px;
		font-weight: 500;
		cursor: pointer;
	}
	&.radio-btn{
		.check{
			border-radius: 10px;
			border-color: #b3b3b3;
			&:after{
				display: none;
			}
		}
	}
	&.secondary-check{
		.check{
			border: none;
			border: 1px solid #c2d5d5;
		}
		input:focus{
			+ {
				label {
					.check {
						box-shadow:none;
						background: none;						
					}
				}
			}
		}
		input:checked + label .check::after{
			border-color: #2e3c56;
			top: 5px;
    		left: 4px;
			border-width: 0 0 3px 3px;
			height: 7px;
    		width: 11px;
		}
		input:checked + label .check{
			background: none;
		}
		.form-check-input:checked{
			background-color: transparent !important;
		}
	}
}
.invalidFeedback{
	width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;
}
.radio-group-wrapper {
	width: 100%;
	margin-bottom: 15px;
	.title-wrapper{
		color: $grey;
    	font-weight: 600;
    	font-size: 14px;
		border-bottom: 1px solid $grey-shade3;
		padding-bottom: 5px;
		margin-bottom: 10px;
	}
	.check-wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;
		&.width-50{
			.custom-check {
				flex: 0 0 50%;
				max-width: 50%;
			}
		}
		.form-check-label{
			color: $grey !important;
		}
	}
}
// custom scroll
.custom-scrollbar{
	.thumb-vertical{
		width: 3px !important;
		background: $blue-shade-6;
		right: -5px;
		&.black-thumb{
			width: 8px !important;
			border-radius: 5px;
			background: $grey-shade19;
			right: 2px;
		}
		&.grey-thumb{
			width: 8px !important;
			border-radius: 5px;
			background: $dark-gray-5;
			right: 2px;
		}
	}
}
// custom switch
.custom-switch input{
	height: 22px;
    min-width: 35px;
    background-color: $grey-shade10;
    border-color: $grey-shade10 !important;
	background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
	background-size: 22px;
}
.form-check-input:checked{
	background-color: $light-green;
    border-color: $light-green !important;
}
.select__control {
	box-shadow: none !important;
	&:hover,&:focus{
		border-color: $blue-shade-8 !important;
	}
}
.multi-select-wrapper{
	.select__multi-value{
		background-color: $grey-shade22;
		border: 1px solid $dark-gray-4;
		border-radius: 4px;
		cursor: default;
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		margin-right: 5px;
		margin-top: 2px;
		padding: 0 5px 0 0;
	}
	.select__multi-value__label{
		font-size: 14px;
		font-weight: 600;
	}

	.select__multi-value__remove{
		color: $grey-shade18;
		&:hover{
			color: $grey-shade2;
			background-color: transparent;
		}
	}
	.select__multi-value__label{
		padding: 0;
	}
}

// custom-dropdown
.custom-dropdown {
	position: relative;
	&:after{
		content: '';
		position: absolute;
		border: 1px solid #000;
		height: 6px;
		width: 6px;
		transform: rotate(-45deg);
		border-top: none;
		border-right: none;
		top: 14px;
		right: -20px;
	}
	.dropdown-item{
		padding:0;
		&:hover{
			background-color: $grey-shade19;
		}
		a{
			font-size: 14px;
			background-color: $black-shade-1;
			color: $grey-shade9 !important;
			padding: 15px;
			height: 50px;
			transition: all 0.3;
			display:block;
		}
	}
	.dropdown-menu{
		padding: 0;
		border-top: 1px solid $blue-shade-4;
        &:before{
            content: '';
            border: 6px solid $blue-shade-4;
            position: absolute;
            border-top-color: transparent;
            border-bottom-color: $blue-shade-4;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            top: -12px;
            left: auto;
            right: 13px;
        }
	}
	.dropdown-toggle::after{
		display: none;
	}
}
[id*="listbox"]{
	margin-top: 1px !important;
	border-radius: 0 0 4px 4px !important;
	[class*="option"]{
		font-size: 14px;
		font-weight: 500;
		color: $grey-shade2;
		background-color: $white;
		&:hover{
			background-color: $primary;
			color: $white;
		}
	}
}
.custom-normal-select{
	label{
		min-width: 150px;
		margin-right: 15px;
	}
	select, .form-control{
		border: 1px solid $dark-gray-1;
		padding: 10px 14px !important;
		height: auto;
		border-radius: 4px;
		font-weight: 400;
		background-color: transparent;
		color: $grey-shade1;
		font-size: 14px;
		font-family: 'Source Sans Pro', sans-serif;
		label{
			color: $black-shade-3;
		}
		&:focus{
			border: 1px solid $primary;
			outline: none;
		}
	}
	select{
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		padding-right: 25px !important;
	}
}
.radio-wrapper{
	display: flex;
	.radio-btn{
		margin-right: 20px;
	}
	.custom-check{
		&.secondary-radio{
			input:checked + label {
				.check{
					box-shadow: inset 0 0 0 4px #fff, inset 0 0 0 9px #2e3c56, 0 0 0 0px #4fdcf1;
				}
			}
		}
	}
}
.date-picker{
	position: relative;
	input{
		height: 42px;
		color: #555;
		font-size: 14px;
		font-weight: 600;
		border-radius: 4px;
		width: 100%;
		padding: 0;
		outline: none;
		padding-left: 10px;
	   }
		.date-set{
			position: absolute;
			right: 0px;
			top: 0;
			height: 41px;
			border-radius: 0 4px 4px 0;
		}
	.ant-picker-focused{
		box-shadow: none;
		border-color: $primary !important;
	}
}
.ant-picker{
	border-radius: 4px !important;
	&:hover, .ant-picker-focused{
		border-color: $primary !important;
		box-shadow: none !important;
	}
	&:after {
		content: "\f073";
		font-family: 'FontAwesome';
		color: #fff;
		background: $blue-shade-6;
		min-width: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 0 4px 4px 0;
		right: 0;
		top: 0;
		bottom: 0;
		position: absolute;
	}
}
.btn.disabled, .btn:disabled{
  cursor: not-allowed;
  opacity: 1;
}

.grey-btn{
  background-color: $grey-shade8;
  border-color: $grey-shade8;
  color: $black-shade-3;
  font-size: 14px;
  &:hover{
    background-color: $black;
    color: $white;
  }
  &.disabled,&:disabled{
    background-color: $grey-shade8;
    border-color: $grey-shade8;
    color: $black-shade-3;
  }
}
.btn-gradient{
  background: linear-gradient(90deg, #5BCFED, black) no-repeat;
  border: transparent;
  i{
    color: $white;
  }
}

.btn-outer{
	&.disabled{
		cursor: not-allowed;
	}
	.btn{
		pointer-events: none;
	}
}
.form-control:disabled{
	background-color: $grey-shade27;
	cursor: not-allowed;
}
.form-control[readOnly]{
	background-color: $grey-shade27;
	border-color: $dark-gray-1;
	&:focus{
		border-color: $primary;
	}
}
.height-34{
	height: 34px;
	line-height: 1;
	font-size: 14px;
}	
.primary-tab{
	.nav-tabs{
		border: none;
		display: flex;
		width: 100%;
	}
	.nav-link{
		border: none;
		background-color:transparent;
		margin: 0;
		padding: 9px 14px;
		border-radius: 0;
		font-weight: 400;
		color: $grey-shade2;
		font-size: 14px;
		line-height: 14px;
		width: 100%;
		border: 1px solid $blue-shade-7;
		&.active{
		  background-color: $blue-shade-7;
		  border: 1px solid $blue-shade-7;
		  color: $white;
		}
		&:hover{
			background-color: $blue-shade-7;
			border: 1px solid $blue-shade-7;
			color: $white;
		}
	  }
	  .nav-item{
		&:first-child{
			.nav-link{
				border-radius: 20px 0 0 20px;
			}
		}
		&:last-child{
			.nav-link{
				border-radius: 0 20px 20px 0;
			}
		}
	  }
}
.custom-perc-group{
	.form-check{
		position: relative;
		padding: 0;
		cursor: pointer;
		input{
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			height: 100%;
			width: 100%;
			margin: 0;  
			opacity: 0;
			&:checked +.form-check-label{
				background-color: $grey-shade11;
			}
		}
		.form-check-label{
			display: inline-flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			padding: 10px;
			min-width: 50px;
			margin: 0;
			color: $grey-shade12;
			height: 42px;
			border: 1px solid #e2e2e2;
		}
	}
	span{
		&:first-child{
			.form-check-label{
				border-radius: 4px 0 0 4px;
			}
		}
	}
	.form-control{
		border-left: none;
		border-radius: 0 4px 4px 0;
		width: 80px;
		font-size: 14px;
    	font-weight: 400;
    	text-align: center;
	}
}
.auto-complete-wrapper{
	position: relative;
	.auto-complete-list{
		border: 1px solid $primary;
		position: absolute;
    	background: $white;
    	width: 100%;
		z-index: 9;
		li{
			padding: 10px 15px;
			font-size: 14px;
			line-height: 1.50;
			color: $grey-shade1;
			margin-right: 10px;
			&:hover{
				background-color: $blue-shade-7;
    			color: $white;
			}
		}
	}
}
.search-gradient-wrapper{
	position: relative;
	input{
		padding-right: 50px;
	}
	.btn-gradient{
		position: absolute;
    	right: 0;
    	top: 0;
    	bottom: 0;
		border-radius: 0 4px 4px 0;
	}
}
.time-picker{
	.react-time-input-picker {
		display: flex;
		width: 100%;
	}
	.react-time-input-picker-wrapper{
		margin: 0;
		border-radius: 4px;
		width: 100%;
		border: 1px solid $grey-shade20;
		height: 42px;
		.inputWrapper{
			font-size: 14px;
		}
		.inputWrapper__dropdown{
			z-index: 9;
		}	
	}
	.inputWrapper:nth-child(1)::after{
		display: none;
	}
}
.rdw-editor-wrapper{
	border: 1px solid $grey-shade29;
	.rdw-editor-toolbar{
	  border-bottom: 1px solid #dfdfdf;
	  margin: 0;
	  background-color: $grey-shade6;
	}
	.editor-class {
	  padding: 10px;
	  .public-DraftEditor-content{
		height: 120px;
	  }
	}
	.rdw-inline-wrapper,.rdw-list-wrapper,.rdw-text-align-wrapper,.rdw-link-wrapper,.rdw-embedded-wrapper,.rdw-emoji-wrapper,.rdw-image-wrapper,.rdw-remove-wrapper,.rdw-history-wrapper{
	  .rdw-option-wrapper{
		margin: 0;
		width: 36px;
		height: 30px;
		&:hover{
		  background-color: $grey-shade11;
		  box-shadow: none;
		}
		img{
		  max-width: 10px;
		  height: 10px;
		}
	  }
	}
	.rdw-dropdown-selectedtext{
	  font-size: 12px;
	  color: $grey-shade15;
	}
	.rdw-dropdown-wrapper{
	  &:hover{
		box-shadow: none;
	  }
	}
  }
  .input-danger{
	  border-bottom: 2px solid $red-shade;
	  position: relative;
	  .text-danger{
		position: absolute;
		right: 10px;
		bottom: 11px;
		font-size: 20px;
	  }
  }
  .input-success{
	border-bottom: 2px solid $green-shade1;
	position: relative;
	.text-success{
	  position: absolute;
	  right: 10px;
	  bottom: 11px;
	  font-size: 20px;
	  color: $green-shade1 !important;
	}
}
.input-group{
	.input-group-btn{
		background-color: $grey-shade27;
		border: 1px solid $grey-shade30;
		padding-left: 10px;
		border-radius: 4px;
		.secondary-check{
			margin: 0 !important;
		}
	}
}
.disable-option{
	cursor: not-allowed;
	pointer-events: none;
	a{
		pointer-events: none;
		color: $grey-shade26 !important;
		span{
			color: $grey-shade26 !important;
		}
	}
	span{
		pointer-events: none;
		color: $grey-shade26 !important;
	}
}
.time-input{
	position: relative;
	span{
		position: absolute;
		top: 37px;
		left: 55px;
		color: #333;
	}
}
.ant-picker-time-panel-column::after{
	display: none !important;
}
.ant-picker-footer{
	.ant-picker-now-btn{
		color: $grey-shade2;
	}
	.ant-btn-primary{
		background-color: $grey-shade2;
		border-color: transparent;
	}
}
.ant-picker-dropdown{
    z-index: 1056 !important;
}

.ant-picker-header{
	padding: 10px 10px 0 !important;
	border: none !important;
	button{
		line-height: 32px !important;
	}
	.ant-picker-header-view{
		font-size: 14px;
		color: $grey-shade2;
		font-weight: 600;
		margin: 0 20px;
		border-radius: 16px;
		height: 32px;
		line-height: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		&:hover{
			background: $black;
			button{
				color: $white !important;
			}
		}
	}
}
.ant-picker-header-prev-btn,.ant-picker-header-next-btn,.ant-picker-header-super-prev-btn,.ant-picker-header-super-next-btn{
	height: 32px;
	width: 32px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover{
		background: $black;
		color: $white !important;
		.ant-picker-prev-icon::before, .ant-picker-next-icon::before{
			color: $white;
			opacity: 1;
		}
	}
}
.ant-picker-prev-icon::before, .ant-picker-next-icon::before{
	border-width: 3px 0 0 3px !important;
	color: #333;
	opacity: 0.6;
	height: 10px !important;
	width: 10px !important;
}
.ant-picker-date-panel{
	font-family: 'Open Sans', sans-serif;
	width: 295px !important;
	.ant-picker-content {
		th{
			font-size: 14px;
			font-weight: 600;
			color: $grey-shade2;
		}
	}
	.ant-picker-cell {
		.ant-picker-cell-inner{
			height: 32px;
			width: 32px;
			line-height: 30px;
			border-radius: 100%;
		}
		&:hover{
			.ant-picker-cell-inner{
				background-color: $black !important;
				color: $white;
			}
		}
	}
	.ant-picker-cell-in-view{
		&.ant-picker-cell-selected {
			.ant-picker-cell-inner{
				background: $black;
			}
		}
		&.ant-picker-cell-today {
			.ant-picker-cell-inner{	
				background: $grey-shade2;
				border: 1px solid $blue-shade-12;
				border-radius: 100%;
				color:$white;
				font-weight: 600;
				&::before{
					display: none;
				}
			}
			&:before{
				content: "";
				display: inline-block;
				border: solid transparent;
				border-width: 0 0 7px 7px;
				border-bottom-color: #fff;
				border-top-color: rgba(0, 0, 0, 0.2);
				position: absolute;
				right: 8px;
				left: auto;
				z-index: 4;
			}
		}
	}
	.ant-picker-header-super-prev-btn,.ant-picker-header-super-next-btn{
		display: none;
	}
}
.ant-picker-year-panel,.ant-picker-month-panel ,.ant-picker-decade-panel{
	.ant-picker-content{
		height: auto;
		max-height: 250px;
		td{	
			height: 50px;
			width: 50px;
		}
		.ant-picker-cell-inner{
			height: 50px;
			width: 50px;
			margin: 0 auto;
			border-radius: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			&:hover{
				background: $grey-shade2;
				color: $white;
			}
		}
	}
	.ant-picker-cell{
		.ant-picker-cell-inner{
			&:hover{
				background: $grey-shade2 !important;
				color: $white;
			}
		}
	}
	.ant-picker-cell-in-view{
		&.ant-picker-cell-selected {
			.ant-picker-cell-inner{
				background: $grey-shade2;
			}
		}
	}
}
.ant-picker-decade-panel{
	.ant-picker-content{
		.ant-picker-cell-inner{
			height: 40px;
			width: 80px;
			border-radius: 20px;
		}
	}
}
.inspection-radio{
	margin-right: 0 !important;
	&.custom-check{
		&.radio-btn {
			.check{
				border-radius: 3px;
				img{
					display: none;
				}
			}
			&.green-radio{
				.check{
					background: $green-shade3;
					border-color: $green-shade3;
				}
				input:focus + {
					label {
						.check{
							background: $green-shade3 !important;
							border-color: $green-shade3 !important;
							box-shadow: inset 0 0 0 20px $green-shade3, 0 0 0 2px rgb(255 255 255), 0 0 0 3px $green-shade3;
							img{
								display: block;
							}
						}
					}
				}
				input:checked + {
					label {
						.check{
							background: $green-shade3 !important;
							border-color: $green-shade3 !important; 
							box-shadow: inset 0 0 0 20px $green-shade3, 0 0 0 2px rgb(255 255 255), 0 0 0 3px $green-shade3;
							img{
								display: block;
							}
						}
					}
				}
			}
			&.yellow-radio{
				.check{
					background: $yellow-shade-5;
					border-color: $yellow-shade-5;
				}
				input:focus + {
					label {
						.check{
							background: $yellow-shade-5 !important;
							border-color: $yellow-shade-5 !important;
							box-shadow: inset 0 0 0 20px $yellow-shade-5, 0 0 0 2px rgb(255 255 255), 0 0 0 3px $yellow-shade-5;
							img{
								display: block;
							}
						}
					}
				}
				input:checked + {
					label {
						.check{
							background: $yellow-shade-5 !important;
							border-color: $yellow-shade-5 !important; 
							box-shadow: inset 0 0 0 20px $yellow-shade-5, 0 0 0 2px rgb(255 255 255), 0 0 0 3px $yellow-shade-5;
							img{
								display: block;
							}
						}
					}
				}
			}
			&.red-radio{
				.check{
					background: $red-shade-4;
					border-color: $red-shade-4;
				}
				input:focus + {
					label {
						.check{
							background: $red-shade-4 !important;
							border-color: $red-shade-4 !important;
							box-shadow: inset 0 0 0 20px $red-shade-4, 0 0 0 2px rgb(255 255 255), 0 0 0 3px $red-shade-4;
							img{
								display: block;
							}
						}
					}
				}
				input:checked + {
					label {
						.check{
							background: $red-shade-4 !important;
							border-color: $red-shade-4 !important; 
							box-shadow: inset 0 0 0 20px $red-shade-4, 0 0 0 2px rgb(255 255 255), 0 0 0 3px $red-shade-4;
							img{
								display: block;
							}
						}
					}
				}
			}
		}
	}
}

.file-attach-btn{
	position: relative;
	height: 34px;
	display: inline-flex;
	font-size: 14px;
	i{
	  margin-left: 5px;
	}  
	span{
	  line-height: 1;
	}
	input{
	  opacity: 0;
	  position: absolute;
	  top: 0;
	  bottom: 0;
	  left: 0;
	  right: 0;
	  height: 100%;
	  width: 100%;
	  cursor: pointer;
	}
  }
  ::-webkit-file-upload-button { cursor:pointer; }
  .remove-icon{
	  height: 20px;
	  width: 20px;
	  display: inline-flex;
	  background-color: $red-shade-2;
	  border-radius: 10px;
	  i{
		  color: $white;
	  }
  }
.multi-select-wrapper .select__indicators{
	display: none;
}
.text-nowrap{
	white-space: nowrap !important;
}
.custom-select{
	[id*=listbox]{
		z-index: 9;
	}
}
.select2-disable{
	cursor: not-allowed;
	.form-control{
		pointer-events: none;
		background-color:$grey-shade27;
		[class*=-control]{
			background-color:$grey-shade27;
		}
	}
}
select:disabled{
	cursor: not-allowed;
	background-color:$grey-shade27;
}
.primary-dropdown{
	border-radius: 24px;
    height: 34px;
    line-height: 1;
    font-size: 14px;
	&.show>.btn-primary{
		&.dropdown-toggle{
			background-color: $blue-shade-6;
			border-color: $blue-shade-6;
		}
	}
}
.status-tab{
	min-height: 22px;
	min-width: 65px;
	display: inline-flex;
	justify-content: center;
	font-family: 'Open Sans', sans-serif;
	color: $white;
	font-size: 12px;
	font-weight: 700;
	border-radius: 20px;
	padding: 3px 8px;
	&.purple-tab{
		background-color: $purple;
	}
	&.green-tab{
		background-color: $green-shade5;
	}
	&.grey-tab{
		background-color: $grey-shade26;
	}
}
.red-text{
	color: $red-shade-6;
}

.table-checkbox{
	padding: 0;
    height: 20px;
    width: 20px;
	top: 5px;
	input{
		top: 0;
		bottom: 0;
		position: absolute;
		left: 0;
		right: 0;
		height: 20px;
		width: 20px;
		margin: 0 !important;
		z-index: 2;
	}
	.form-check-input[type=checkbox]:indeterminate{
		background-color: $primary;
		opacity: 1;
		border-color: $primary;
		border-radius: 0;
	}
}
@media print{
	.bg-black{
		background-color: $black;
		-webkit-print-color-adjust: exact !important;
	}
	.has-bg{
		background-image: url('../images/curve-b2.png');
		-webkit-print-color-adjust: exact !important;
		color-adjust: exact !important;
	}
  }

input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    pointer-events: none;
}

