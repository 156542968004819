@mixin sm {
    @media (max-width: 991px) {
        @content;
    }
 }
 @mixin md {
    @media (max-width: 1024px) {
        @content;
    }
 }
 @mixin lg {
    @media (max-width: 1199px) {
        @content;
    }
 }
 @mixin xl {
    @media (max-width: 1366px) {
        @content;
    }
 }
 @mixin xxl {
    @media (max-width: 1439px) {
        @content;
    }
 }
 