.dark-theme{
    .header-wrapper{
        background: linear-gradient(90deg, rgb(91, 207, 237), rgb(26, 26, 26) 60%);
        color: $white;
        .left-header {
            .workshop-name{
                color: $white;
            }
            .business-dropdown {
                &:after{
                    border-color: $white;
                }
                .btn-primary{
                    color: $white;
                }
            }
        }
        .profile-wrapper {
            i{
                color: $white !important;
            }
        }
    }
    .sidebar-wrapper{
        background: $black;
        .top-list{
            background: linear-gradient(#5BCFED,#1a1a1a);
        }
        .bottom-list{
            position: relative;
            &:after{
                content:'';
                position: absolute;
                top: -30px;
                left: 0;
                right: 0;
                list-style-type: none;
                height: 30px;
                clip-path: polygon(0px 100%, 100% 0px, 100% 100%, 0% 100%);
                background: $black;
            }
        }
        > ul {
            > li {
                
                > a {
                    .normal-img{
                        display: none;
                    }
                    .hover-img{
                        display: block;
                    }
                    i{
                        color: $blue-shade-3;
                    }
                    span{
                        color: $white;
                    }
                    &:hover{
                        background-color: $blue-shade-3;
                        span{
                            color: $black;
                        }
                        i{
                            color: $white;
                        }
                        .dark-theme-img{
                            display: block;
                        }
                        .hover-img{
                            display: none;
                        }
                    }
                }
                .sub-menu{
                    background-color: $black;
                    li{
                        a{
                            background-color: $black;
                            color: $white;
                            &:hover{
                                background-color: $blue-shade-3;
                                color: $grey-shade2;
                            }
                        }
                    }
                }
            }
            &.bottom-list{
                background-color: $black;
            }
        }
        .sidebar-logo{
            a{
                &:hover{
                    background-color: $black;
                }
            }
            .light-theme{
                display: none;
            }
            .dark-theme{
                display: inline-block;
            }
        }
    }
}