@import "../../../../assets/scss/color.scss";

.enable-disable {
  .form-switch .form-check-input {
    width: 4em !important;
    margin-left: -2.5em !important;
    border-radius: 2em !important;
    transition: background-position 0.15s ease-in-out !important;
    height: 2em !important;
    cursor: pointer;
  }
}
.asset-vehicle-type {
  .form-switch .form-check-input {
    width: 3.5em !important;
    height: 2em !important;
    cursor: pointer;
  }
}
.time-input.time-input-no-label {
  border: 1px solid;
}
.react-time-input-picker-wrapper {
  border-bottom: none;
  width: 100%;
}

.react-time-input-picker-wrapper .inputWrapper input {
  width: 35px;
}

.time-input-no-label {
  border-radius: 4px;
  border-color: #ced4da !important;
}

// .react-time-input-picker input {
//   background: transparent;
//   color: #767676 !important;
// }

.react-time-input-picker-wrapper {
  margin: 2px 0 1px;
}

.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  background-color: $primary;
  border-color: $primary;
  color: #ffffff;
}
.btn-check:active + .btn-outline-light,
.btn-check:checked + .btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active {
  background-color: #e3e3e3;
  border-color: #e3e3e3;
}
.btn-outline-light {
  border-color: #ced4da;
}
.form-input-btn-group {
  display: flex;
  .btn-group > .btn {
    padding-top: 10px;
    padding-bottom: 9px;
    font-size: 14px;
  }
  .btn-group > .btn:last-child:not(:first-child) {
    border-radius: 0;
    border-right: 0;
  }
  .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.max-width-80 {
  max-width: 80px;
}
.btn-simple,
.btn-simple-primary {
  background-color: transparent;
  -webkit-transition: background-color 0.25s ease;
  transition: background-color 0.25s ease;
}

.btn-simple:active,
.btn-simple:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.btn-simple:active:focus,
.open .btn-simple {
  background-color: rgba(0, 0, 0, 0.1);
}

.table-header .table-header-tabs .nav-tabs .nav-link {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

// @media (max-width: 1400px) {
//   .table-header {
//     .top-block {
//       padding-bottom: 20px;
//     }
//     .table-title {
//       position: relative;
//     }
//     .video-link-outer {
//       position: relative;
//     }
//     .profile-tabs {
//       .tab-content {
//         margin-top: 0;
//       }
//     }
//     .table-header-tabs {
//       align-items: stretch;
//       .nav-tabs {
//         .nav-link {
//           min-width: 120px;
//         }
//       }
//     }
//   }
// }
