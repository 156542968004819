@import "../../../assets/scss/color.scss";

.dashboardWrapper{
    .blockWrapper{
        min-height: 130px;
       i{
           font-size: 25px;
           margin: 15px;
       }
       h1{
           font-size: 36px;
           font-weight: 300;
           font-family: 'Source Sans Pro', sans-serif;
       }
       img{
           height: 25px;
           width: 25px;
           margin: 15px;
       }
       .rightBlock{
           padding-left: 25px;
           border-left: 1px solid $white;
       }
    }
    .blueTiles{
        background-color: $blue-shade-4;
        color: $white;
        h1{
           color: $white;
        }
    }
    .orangeTiles{
        box-shadow: 0 0 22px 0 rgb(241, 168, 167);
        color: $red-shade-1;
        h1{
            color: $red-shade-1;
        }
        .rightBlock{
            border-left: 1px solid $red-shade-1;
        }
    }
    .purpleTiles{
        background-color: $purple-shade;
        color: $white;
        h1{
            color: $white;
        }
    }
    .greenTiles{
        box-shadow: 0 0 22px 0 #98e6d4;
        color: $green-shade1;
        h1{
            color: $green-shade1;
        }
        .rightBlock{
            border-left: 1px solid $green-shade1;
        }
    }
}
.chartWrapper{
    box-shadow: 0 0 22px 0 $dark-gray-3;
    height: 100%;
    border-radius: 5px;
    .chartTitle{
        padding: 15px;
        border-bottom: 1px solid $grey-shade6;
        span{
            color: $black-shade-2;
            text-transform: uppercase;
            font-size: 13px;
            letter-spacing: 1px;
        }
    }
    .DropdownBtn{
        button{
            border: none;
            background:none;
            border-radius: 0;
            padding: 5px;
            height: 30px;
            width: 30px;
            line-height: 1;
            &:hover{
                background-color: rgba(0, 0, 0, 0.07) !important;
            }
            i{
                color: $black-shade-2;
                font-size: 18px;
            }
            &::after{
                display: none;
            }
        }
    }
}
.chartBlock{
    padding: 15px;
    ul{
        display: flex;
        text-align: center;
        li{
            padding: 0 15px;
            h3{
                font-size: 24px;
                font-family: 'Source Sans Pro', sans-serif;
                color: $black-shade-3;
            }
            label{
                font-size: 14px;
                color: $grey-shade26;
            }
            &:first-child{
                border-left: 3px solid $blue-shade-14;
            }
            &:nth-child(2){
                border-left: 3px solid $blue-shade-15;
            }
            &:last-child{
                border-left: 3px solid $blue-shade-16;
            }
        }
    }
  }
.BookingDiaryWrapper{
    padding: 20px;
    .HourCapacityWrapper{
        box-shadow: $box-shadow;
        border-radius: 5px;
        .heading{
            padding: 5px;
            border-bottom: 1px solid $grey-shade13;
        }
        .valueWrapper{
            border-right: 1px solid $grey-shade13;
            &:last-child{
                border: none;
            }
            b{
                font-size:12px;
                color: $black-shade-3;
            }
            span{
                font-size:12px;
                color: $grey-shade26;
            }
        }
        .btnWrapper{
            button{
                height: 32px;
                width: 100%;
                font-size:14px;
                line-height: 1px;
                border-radius: 0 0 5px 5px;
                color: $blue-shade-10;
            }
        }
    }
    .currentTimer{
        background-color: $black-shade;
        border-radius: 7px;
        padding: 5px 20px 5px 10px;
        i{
            color: $blue-shade-4;
            font-size: 30px;
            margin-right: 10px;
        }
        h1{
            font-size: 24px;
            line-height: 1.5;
            color: $white;
            font-family: 'Source Sans Pro', sans-serif;
        }
        p{
            color: $blue-shade-10;
            font-size: 16px;
            font-family: 'Source Sans Pro', sans-serif;
        }
    }
}
.customCalenderHeading{
    h1{
        font-size: 24px;
        font-family: 'Source Sans Pro', sans-serif;
    }
}
.customCalenderButton{
    button{
        height: 70px;
        width: 70px;
        border-radius: 35px;
        background-color: transparent;
        border-color: transparent;
        p{
            font-size: 14px;
            line-height: 1;
            font-weight: 600;
            text-transform: uppercase;
            color:$grey-shade15;
        }
        h4{
            font-size: 18px;
            line-height: 1;
            font-weight: 700;
            color:$grey-shade15;
            font-family: 'Source Sans Pro', sans-serif;
        }
        &:focus{
            background-color: $grey-shade11;
            border-color: transparent;
        }
        &:hover{
            background-color: $grey-shade11;
            border-color: transparent;
        }
        &.active{
            background-color: $grey-shade2 !important;
            border-color: $grey-shade2 !important;
            p{
                font-size: 18px;
                color: $white;
            }
            h4{
                font-size: 24px;
                color: $blue-shade-4;
            }
        }
        &.iconBtn{
            width: 30px;
            color: $grey-shade15;
            font-size: 40px;
            font-weight: 300;
            padding: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.dashboardCardHeader{
    border-bottom:1px solid $blue-shade-2;
    padding: 15px;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.ratingOuter{
    padding: 15px;
    text-align: center;
    .btn-primary{
        width: calc(100% - 30px);
        margin: 30px 15px;
    }
    h1{
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 36px;
    }
    h4{
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 24px;
        color: $black-shade-3;
    }
    .ratingWrapper{
        margin: 0 auto;
        max-width: 210px;
    }
    button{
        margin: 30px 15px;
        width: calc(100% - 30px);
    }
    .ratingBlock{
        .progress{
            width: 140px;
        }
        label,span{
            color: $grey-shade26;
        }
    }
    .progressBar{
        width: 140px;
        height: 14px;
        border-radius: 0;
        background-color: transparent;
    }
}
.customSlider{
    height: 100%;
    .carouselItem{
        height:100%;
    }
}