$white:#fff;
$black:#000;

$black-shade:#2b2b2c;
$black-shade-1:#2D2D2D;
$black-shade-2:#232425;
$black-shade-3:#323232;
$black-shade-4:#373a46;
$dark-gray:#ddd;
$dark-gray-1:#e1eaea;
$dark-gray-2:#989898;
$dark-gray-3:#d8d8d8;
$dark-gray-4:#aaa;
$dark-gray-5:#7F7F7F;
$dark-gray-6:#cccccc;
$dark-gray-7:#b7b7b7;
$dark-gray-8:#676767;
$dark-gray-9:#595959;
$dark-gray-10:#b2b2b2;

$primary:#4eddf2;
$primary-hover:#13b8d1;

$grey:#b3b3b3;
$grey-shade1:#555;
$grey-shade2:#333;
$grey-shade3:#d3d3d3;
$grey-shade4:#e7eaf0;
$grey-shade5:#ececec;
$grey-shade6:#f3f3f3;
$grey-shade7:#797979;
$grey-shade8:#efefef;
$grey-shade9:#d4dade;
$grey-shade10:#e8e8e8;
$grey-shade11:#e2e2e2;
$grey-shade12:#444;
$grey-shade13:#dee2e6;
$grey-shade14:#f5f5f5;
$grey-shade15:#3a3a3a;
$grey-shade16:#6c757d;
$grey-shade17:#cfcff3;
$grey-shade18:#999;
$grey-shade19:#3f3f3f;
$grey-shade20:#ced4da;
$grey-shade21:#CCCCCC;
$grey-shade22:#e4e4e4;
$grey-shade23:#eaeaea;
$grey-shade24:#e5e5e5;
$grey-shade25:#666;
$grey-shade26:#777;
$grey-shade27:#eee;
$grey-shade28:#949494;
$grey-shade29:#a9a9a9;
$grey-shade30:#dfdfdf;

$light-shade:#f7f7f7;

$blue:#115997;
$blue-shade:#23527c;
$blue-shade-1:#337ab7;
$blue-shade-2:#4fdcf1;
$blue-shade-3:#52daee;
$blue-shade-4:#1cc9e4;
$blue-shade-5:#3fb1ca;
$blue-shade-6:#11cfed;
$blue-shade-7:#13b8d1;
$blue-shade-8:#14b9d2;
$blue-shade-9:#11a4bb;
$blue-shade-10:#19c0cc;
$blue-shade-11:#12b0c8;
$blue-shade-12:#0fb2cb;
$blue-shade-13:#52d9ee;
$blue-shade-14:#4b78c4;
$blue-shade-15:#09c3d0;
$blue-shade-16:#08c8b0;
$blue-shade14:#15d3b2;

$light-green:#79d4a7;

$green:green;
$green-shade:#65cc88;
$green-shade1:#48d3b2;
$green-shade2:#70BF4C;
$green-shade3:#21cca4;
$green-shade4:#3fbf6a;
$green-shade5:#00C335;
$green-shade6:#39be39;

$red:#fd1010;
$red-shade:#dc3545;
$red-shade-1:#e55957;
$red-shade-2:#e64b3b;
$red-shade-3:#EB2327;
$red-shade-4:#f90027;
$red-shade-5:#c74134;
$red-shade-6:#ff0804;
$red-shade-7:#CC931A;
$red-shade-8:#e55957;

$yellow:#e6a821;
$yellow-shade-1:#ffd200;
$yellow-shade-2:#ffd726;
$yellow-shade-3:#f9990b;
$yellow-shade-4:#F4B819;
$yellow-shade-5:#f0c938;

// purple color
$purple-shade:#a979d1;

$box-shadow:0 0 22px 0 $dark-gray-3;
// purple
$purple:#8d22d9;
