.header-outer{
    position: sticky;
    top: 0;
    z-index: 9;
    margin-bottom: 15px;
    .subscribe-header{
        background-color: $black;
        padding: 10px;
        border-radius: 8px 8px 0px 0px;
        margin-bottom: 5px;
        font-family: 'Open Sans', sans-serif;
        p{
            font-size: 14px;
            color: $white;
        }
        .center-align{
            display: flex;
            align-items: center;
            button{
                height: 34px;
                border-radius: 17px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 15px;
                font-size: 14px;
            }
            p{
                font-size: 14px;
                color: $white;
            }
        }
    }
    .subscription-expire-note{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $black;
        padding: 10px;
        border-radius: 8px 8px 0px 0px;
        font-family: 'Open Sans', sans-serif;
        p{
            font-size: 14px;
            color: $white;
        }
        button{
            height: 34px;
            border-radius: 17px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 15px;
            font-size: 14px;
        }
    }
}
.header-wrapper{
    background: linear-gradient(90deg, #5BCFED, white) no-repeat;
    padding: 0 0 0 20px;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0px 0px 8px 8px;
    font-family: 'Open Sans', sans-serif;
    .left-header{
        display: flex;
        align-items: center;
        height: 60px;
        .workshop-name{
            border-right: 1px solid $blue-shade-2;
            padding-right: 15px;
            margin-right: 15px;
            height: 100%;
            display: flex;
            align-items: center;
            color: $black;
            font-size: 16px;
        }
        .business-dropdown{
            .dropdown-toggle{
                padding-right: 60px;
                position: relative;
                z-index: 2;
            }
            &:after{
                right: 10px;
            }
            .dropdown-menu{
                width: 200px;
                margin-top: 10px;
                margin-left: -15px;
            }
            .btn-primary{
                background: transparent;
                border: transparent;
                color: $black;
            }
        }
    }
    .right-header{
        > ul{
            display: flex;
            > li{
                border-right: 1px solid $blue-shade-2;
                cursor: pointer;
                i{
                    color: $blue-shade-3;
                }
                &:last-child{
                    border: none;
                }
                > i{
                    min-height: 60px;
                    display: flex;
                    align-items: center;
                }
            }
        }
        .toggle-link{
            padding: 0 20px;
            min-height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            position: relative;
        }
    }
    .global-search{
        display: flex;
        align-items: center;
        position: relative;
        .toggle-link{
            padding-right: 5px;
        }
        .search-input{
            display: flex;
            align-items: center;
            position: relative;
            input{
               border-radius: 15px;
               height: 100%;
               border: 1px solid $blue-shade-3;
               height: 30px;
               padding: 0 40px 0 10px;
               width: 200px;
               background-color: $grey-shade4;
               transition: all 0.3s;
               display: none;
               &:focus{
                   outline: none;
                   height: 40px;
                   width: 250px;
                   border-width: 2px;
               }
            }
            &.active{
                input{
                   display: inline-block;
                }
                i{
                    color: $black;
                }
            }
           i{
               position: absolute;
               right: 15px;
           }
        }
        .search-filter-wrapper{
            position: absolute;
            height: 400px;
            max-height: 400px;
            border-radius: 1.5rem;
            box-shadow: 0 3px 5px 1px #b4b4b4;
            background-color: #fff;
            overflow: hidden;
            right: 0;
            left: auto;
            width: 600px;
            top: calc(100% + 10px);
            z-index: 2;
            .tab-content,.tab-pane{
                height: 345px;
            }
            .nav-tabs{
                padding: 10px;
                border: none;
                border-bottom: 1px solid $grey-shade13;
                margin: 0 !important;
                .nav-link{
                    border: none;
                    padding: 5px 15px;
                    height: 34px;
                    min-width: 130px;
                    border-radius: 20px;
                    margin-right: 10px;
                    color: $grey-shade12;
                    transition: all 0.3s;
                    &:hover{
                        background-color: $black;
                        color: $white;
                    }
                    &.active{
                        background-color: $grey-shade11;
                    }
                }
            }
            .content-bock{
                padding: 15px;
                .no-result{
                    display: flex;
                    justify-content: center;
                    img{
                        max-width: 525px;
                        max-height: 325px;
                    }
                }
            }
            .order-list{
                > li{
                    border-bottom: 1px solid $grey-shade13;
                    padding: 15px;
                }
                .order-info{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 15px;
                    > div {
                        flex: 0 0 33.33%;
                       max-width: 33.33%;
                       display: flex;
                    }   
                    i{
                        margin-right: 10px;
                        font-size: 20px;
                    }
                    span{
                        color: $black;
                        font-size: 14px;
                        transition: all 0.3s;
                        word-break: break-all;
                    }
                    &:hover{
                        span{
                            color: $blue-shade-4;
                        }
                    }
                }
                .order-detail-outer{
                    background-color: $grey-shade14;
                    border-radius: 10px;
                }
                .order-detail{
                    padding: 15px;
                    display: flex;
                    justify-content: space-between;
                    .left-block{
                        display: flex;
                        align-items: center;
                        color: $grey-shade2;
                        .rego-link{
                            color: $green;
                        }
                    }
                    .icon-block{
                        height: 36px;
                        width: 36px;
                        border: 1px solid $grey-shade16;
                        border-radius: 100%;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 10px;
                    }
                    .width-33 {
                        flex: 0 0 33.33%;
                        max-width: 33.33%;
                    }
                    span{
                        color: $black;
                        margin-right: 5px;
                    }
                    .btn-wrapper{
                        display: flex;
                        .border-btn ,.order-id {
                            margin-right: 10px;
                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                    .order-id {
                        padding: 5px 10px;
                        border: 1px solid $grey-shade11;
                        background: $white;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        color: $blue-shade-6;
                        min-width: 110px;
                        span{
                            color: $blue-shade-6;
                            margin-left: 5px;
                        }
                        &:hover{
                            background: $blue-shade-6;
                            border-color: $blue-shade-6;
                            color: $white;
                            span{
                                color: $white;
                            }
                        }
                    }
                    .border-btn {
                        padding: 5px;
                        border: 1px solid $grey-shade11;
                        background: $white;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        color: $grey-shade15;
                        min-width: 110px;
                        &:hover{
                            background: $blue-shade-6;
                            border-color: $blue-shade-6;
                            color: $white;
                            span{
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
    .header-filter-wrapper{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .plus-icon{
            display: flex;
            height: 20px;
            width: 20px;
            min-height: 20px;
            border: 1px solid $blue-shade-3;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            transition: all 0.3s;
        }
        &.active-menu{
            .plus-icon{
                transform: rotate(45deg);
            }
        }
    }
    .header-filter{
        position: absolute;
        min-width: 450px;
        background: #fff;
        box-shadow: 0 3px 5px 1px #b4b4b4;
        padding: 20px;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
        top: 100%;
        &:before{
            content: '';
            border: 6px solid $blue-shade-4;
            position: absolute;
            border-top-color: transparent;
            border-bottom-color: $blue-shade-4;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            top: -12px;
            left: 50%;
            transform: translateX(-50%);
        }   
        
        .top-block{
            margin-bottom: 10px;
        }
        ul{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li{
                flex: 0 0 calc(50% - 5px);
                max-width: calc(50% - 5px);
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $red;
                min-height: 60px;
                margin-bottom: 10px;
                transition: all 0.3s;
                &:hover{
                    background-color: $light-shade;
                }
                a{
                    display: block;
                    padding:10px 8px;
                    color: $red-shade-1;
                    &:hover{
                        color: $red;
                    }
                }
                .icon-block{
                    height: 20px;
                    width: 20px;
                    display: inline-block;
                    margin-right: 5px;
                    color:$red-shade;
                    font-size: 18px;
                }
            }
        }
        .bottom-block{
            ul{
                li{
                    flex: 0 0 calc(33.33% - 15px);
                    max-width: calc(33.33% - 15px);
                    border-color: $black;
                    .icon-block{
                        color:$black;
                    }
                    a{
                        color:$grey-shade12;
                        &:hover{
                            color: $black;
                        }
                    }
                }
            }
        }
    }
    .header-notification-wrapper{
        position: relative;
        .notification-icon{
            position: relative;
            .badge{
                position: absolute;
                top: -13px;
                right: -10px;
                padding: 3px 5px;
                font-size: 11px;
                font-weight: 500;
                color: #fff;
                font-family: 'Montserrat', sans-serif;
            }
        }
        .notification-inner{
            min-width: 300px;
            background: $white;
            position: absolute;
            right: 0;
            z-index: 2;
            border: 1px solid $grey-shade5;
            border-top: 1px solid $yellow-shade-1;
            &:before{
                content: '';
                border: 6px solid $yellow-shade-1;
                position: absolute;
                border-top-color: transparent;
                border-bottom-color: $yellow-shade-1;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                top: -12px;
                left: auto;
                right: 20px;
            } 
            .notification-heading{
                padding: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid $grey-shade8;
                span{
                    font-size: 13px;
                    font-weight: 500;
                    color: $grey-shade7;
                    letter-spacing: -0.5px;
                }
            }
            .header-notification-list{
                li{
                    padding: 0 15px;
                    &:last-child{
                        a{
                            border: none;
                        }
                    }
                    a{
                        padding: 15px 0;
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid $grey-shade6;
                        min-height: 80px;
                        span{
                            min-width: 35px;
                            width: 35px;
                            height: 35px;
                            border-radius: 100%;
                            margin-right: 10px;
                            background: $blue-shade-4;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            i{
                                color: #fff;
                            }
                        }
                        p{
                            color: $black-shade;
                            font-size: 14px;
                            line-height: 22px;
                        }
                    }
                    &:hover{
                        p{
                            color: $blue-shade-5;
                        }
                    }
                }
            }
        }
    }
    .help-notifications{
        position: relative;
    }
    .help-notifications-inner, .profile-list{
        min-width: 230px;
        background: $black-shade-1;
        position: absolute;
        right: 0;
        left: auto;
        z-index: 2;
        border-top: 1px solid $blue-shade-4;
        &:before{
            content: '';
            border: 6px solid $blue-shade-4;
            position: absolute;
            border-top-color: transparent;
            border-bottom-color: $blue-shade-4;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            top: -12px;
            left: auto;
            right: 20px;
        } 
        li {
            a{
                padding: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: $grey-shade9;
                i{
                    color: $white;
                    font-size: 16px;
                }
                img{
                    max-width: 20px;
                }
                &:hover{
                    background-color: $grey-shade19;
                }
            }
        }
        .switch-wrapper{
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $grey-shade9;
            input{
                cursor: pointer;
            }
        }
    }
    .account-block{
        position: relative;
    }
    .profile-wrapper{
        display: flex;
        align-items: center;
        min-height: 60px;
        i{
            color: #000 !important;
            margin-left: 10px;
        }
        .profile-icon{
            height: 30px;
            width: 30px;
            border-radius: 100%;
            background: #000;
            margin-right: 10px;
            overflow: hidden;
        }
    }
    .custom-header-dropdown{
        display: none;
    }
    .body-overlay{
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        visibility: hidden;
    }
    .active-menu{
        .custom-header-dropdown{
            display: block;
        }
        .body-overlay{
            opacity: 1;
            visibility: visible;
        }
    }
    .view-more-wrapper{
        border-top:1px solid $grey-shade17;
        display: flex;
        justify-content: center;
        margin-top: 25px;
        padding-bottom: 10px;
    }
    .view-more-btn{
        border-radius: 100px;
        background: $grey-shade14;
        border: 1px solid $grey-shade17;
        padding: 4px 10px;
        margin-top: -13px;
        color: $blue;
    }
}