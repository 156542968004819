@import "../../../assets/scss/color.scss";

.purchase-order-wrapper{
    .purchase-order-heading{
      padding: 15px;
      box-shadow: 0 0 22px 0 $dark-gray-3;
      border-radius: 5px;
      margin-bottom: 30px;
      h3{
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 18px;
        color: $black-shade-2;
        letter-spacing: 1px;
      }
      .order-id{
        color: red;
        // color: rgb(45, 104, 109);
        font-size: 23px;
        font-style: italic;
      }
      .btn-wrapper{
        .btn{
          margin-left: 10px;
        }
      }
    }
    .purchase-order-filter{
      box-shadow: 0 0 22px 0 $dark-gray-3;
      border-radius: 5px;
      padding: 15px;
      .panel-heading{
        padding: 15px;
      }
    }
    .accordion-item{
      border: none;
      .accordion-header{
        .accordion-button{
          padding-top: 0;
          box-shadow: none;
          border-bottom: 1px solid $grey-shade6;
          display: flex;
          justify-content: space-between;
          .la-angle-down{
            background-color: $grey-shade8;
            padding: 5px;
          }
          &:after{
            display: none;
          }
        }
      }
      .ant-picker{
        max-width: 200px;
      }
      .date-picker{
        &.width-100{
          .ant-picker{
            max-width: 100%;
          }
          .date-set{
            right: 0;
          }
        }
      }
      .date-picker {
        .date-set{
          top: 0;
          right: 53px;
        }
      }
      label{
        margin: 0 10px 0 0;
        min-width: auto;
        &.width-100{
          min-width: 100px;
        }
      }
      .date-created{
        .fa-calendar{
          margin-right: 5px;
        }
      }
      .radio-wrapper {
        .radio-btn{
          margin: 0 0 -5px 0;
          padding-left: 20px;
        }
      }
      .radio-block{
        flex: 0 0 calc(50% - 100px);
        max-width: calc(50% - 100px);
        .form-control,select{
          min-width: 250px;
        }
      }
      .select-wrapper{
        .form-group{
          max-width: 100%;
        }
      }
    }
    .info-block{
      width: 50%;
      .width-50{
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 10px;
      }
      i{
        margin-right: 10px;
      }
      .outer-div{
        margin: 0 -10px;
      }
      a{
        color: $blue;
      }
    }
    .order-detail-outer{
    border-top: 1px solid $grey-shade13;
    .credit-btn{
      height: 36px;
      font-size: 14px;
      line-height: 1;
      margin: 10px 0;
    }
    .order-for-job{
        box-shadow: 0 0 22px 0 $dark-gray-3;
        border-radius: 5px;
        padding: 15px;
        .title-block{
        margin: 0 -15px 15px;
        border-bottom: 1px solid $grey-shade6;
        padding: 0 15px 15px;
        h4{
            font-size: 18px;
        }
        }
    }
    }
}
.detail {
  display: flex;
  padding-top: 15px;
  padding-bottom: 10px;
  color: #676767;
  align-items: center;
  justify-content: space-between;

  a {
      display: inline-flex;
      align-items: center;

      i {
          font-size: 20px;
          color: #11cfed;
          margin-right: 5px;
      }

      color: #115997;

      &:hover {
          color: #11cfed;
      }
  }
}
.comment-total-block{
.comment-block{
    flex:0 0 50%;
    max-width: 50%;
}
.form-outer{
    flex: 0 0 50%;
    max-width: 50%;
    .form-control,.value{
      width: 172px;
      font-weight: 400;
    }
    .form-group{
    justify-content: flex-end;
    label{
        margin: 0 30px 0 0;
    }
    }
    .total-amount{
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 18px;
      font-weight: 500;
      color: $black-shade-3;
    }
}
&.multi-col-total{
  .comment-block{
    flex:0 0 100%;
    max-width: 100%;
  }
  .form-outer{
      flex: 0 0 100%;
      max-width: 100%;
    .form-control{
      width: 125px;
    }
  }
  .panel-heading{
    border-bottom: 1px solid $grey-shade6;
    font-family: 'Source Sans Pro', sans-serif;
    &.text-right{
      text-align: right;
    }
  }
}
}
.data-block-wrapper{
border-bottom: 1px solid $grey-shade13;
.data-block{
    flex: 0 0 25%;
    max-width: 25%;
    i{
    margin-right: 10px;
    }
    a{
    color: $blue;
    }
    .select-block{
    padding-right: 30px;
    select{
        width: 100%;
    }
    }
}
}


