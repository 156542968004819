.font-size-15{
    font-size: 15px !important;
}
.fc-toolbar-chunk{
    display: flex;
}
.fc-button-group{
    button{
        text-transform: capitalize !important;
    }
}

.fc-resourceTimelineDay-button{
    border-radius: 50px 0px 0px 50px!important;
    background-color: #59cfec!important;
    border-color: #fff!important;
}

.fc-dayGridMonth-button{
    border-radius: 0px 50px 50px 0px!important;   
    background-color: #59cfec!important;
    border-color: #fff!important;
}

.fc-resourceTimelineWeek-button{
    background-color: #59cfec!important;
    border-color: #fff!important;
}

.fc-unassignedButton-button{
    padding: 5px!important;
    border-radius: 20px!important;
    background-color: #e64b3b!important;
    border-color: #e64b3b!important;
}

.fc .fc--button{
    display: none!important;
}
.fc .fc-timeline-slot-cushion{
    color: black!important;
    font-weight: 400;
}
.fc-datagrid-cell-main{
    padding: 10px 5px!important;
    display: block;
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    text-align: center;
    -webkit-box-shadow: 0 0 22px 0 $dark-gray-3;
    box-shadow: 0 0 22px 0 $dark-gray-3;
}

.divImg{
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: normal;
    padding: 4px;

    img {
        height: 44px;
        margin-right: 4px;
    }
}
.fc-scrollgrid-section-header{
    .fc-datagrid-cell-cushion{
        width: 100%;
        padding: 0 !important;
    }
    .fc-datagrid-cell-main{
        box-shadow: none;
        padding: 0 !important; 
    }
    .count{
        height: 26px;
        width: 26px;
        min-width: 26px;
        border-radius: 100%;
        border: 1px solid $black-shade-3;
        // margin-left: 10px;
        font-size: 18px;
        font-weight: 400;
        line-height: 1;
    }
}
.schedular-outer{
    display: flex;
    .right-col{
        flex: 0 0 17%;
        margin-left: 15px;
        box-shadow: 0 0 22px 0 $dark-gray-3;
        border-radius: 5px;
        overflow: hidden;
        word-wrap: break-word;
        word-break: break-word;
        background: #fff;
        padding: 15px;
        display: none;
        width: 100%;
        .block-title{
            height: 35px;
            background-color: $red-shade-2;
            font-size: 14px;
            color: $white;
            border-radius: 16px;
            padding: 0 5px;
            margin-bottom: 10px;
            .count{
                height: 26px;
                width: 26px;
                border-radius: 100%;
                border: 1px solid $white;
                margin-left: 10px;
                font-size: 18px;
                line-height: 1;
            }
            &.black-bg{
                background-color: $grey-shade2;
            }
        }
        .unassigned-list{
            width: 100%;
        }
        .job-tiles-wrapper{
            border-top: 1px solid #dee2e6 !important;
            padding-top: 10px;
            margin-bottom: 40px;
            .job-tiles{
                border-left: 4px solid $yellow-shade-2;
                box-shadow: 0 0 22px 0 $dark-gray-3;
                margin-bottom: 10px;
                padding: 5px;
                .rego-id{
                    color: $black;
                }
                p{
                    font-size: 11px;
                    line-height: 16px;
                    font-weight: 700;
                    color: $grey-shade25;
                    margin: 0 !important;
                }
            }
        }
    }
    &.display-right-col{
        .right-col{
            display: block;
        }
    }
    .fc-header-toolbar{
        margin-bottom: 10px!important;
        [class*='unassignedButton-button']{
            height: 35px;
            align-items: center;
        }
        .fc-toolbar-title{
            font-size: 19px;
            font-weight: 500;
            margin-left: 10px !important;
        }
        .fc-toolbar-chunk{
            &:first-child{
                .fc-button-group{
                    button{
                        padding: 6px 12px;
                    }
                }
            }
        }
        .fc-toolbar-chunk{
            &:nth-child(2){
                .fc-button-group {
                    .fc-button-primary{
                        border-radius: 100%;
                        height: 25px;
                        width: 25px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: transparent;
                        color: $black;
                        &:hover{
                            background-color: $black;
                            color: $white;
                        }
                        .fc-icon-chevron-left{
                            position: relative;
                            left: -1px;
                            top: -1px;
                        }
                        .fc-icon-chevron-right{
                            position: relative;
                            right: -1px;
                            top: -1px;
                        }
                    }   
                }
            }
        }
    }
    // .fc-timeline-slot:hover{
    //     // background-color: #aaa;
    // }
    .fc-datagrid-cell-cushion{
        padding:8px 0 !important;
    }
}
.resource-outer{
    display: flex;
    align-items: center;
    .resource-profile{
        display: inline-flex;
        height: 40px;
        width: 40px;
        border-radius: 100%;
        overflow: hidden;
        border: 1px solid #11cfed;
        background-color: #e6e6e6;
        margin-right: 10px;
        img{
            height: 100%;
            width: 100%;
        }
        .mechanic-img{
            margin: 7px;
            height: auto;
            width: auto;
        }
    }
    .right-block{
        display: flex;
        flex-direction: column;
        p{
            text-align: left;
            text-transform: capitalize;
            font-size: 14px;
            line-height: 20px;
            color: $black-shade-3;
            margin-bottom: 5px;
        }
        .booked-hours{
            font-size: 12px;
            line-height: 14px;
            color: $grey-shade28;
        }
    }
    .progress{
        background-color: #ff7777;
        height: 8px;
        width: 160px;
        .progress-bar{
            background: linear-gradient(to bottom, #25a552, #34ea7d);
            border-radius: 10px;
        }
    }
  
}
.resource-tooltip{
    background-color: #ced0b9;
    box-shadow: 1px 1px 3px #fff;
    opacity: 5;
    z-index: 99;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-top: 2px;
    padding: 0;
    .tooltip-arrow{
        display: none;
    }  
    .tooltip-inner{
        background: transparent;
        color: $black;
        padding: 3px 8px;
    }
}
.time-zone{
    .time-slot{
        flex:0 0 33.33%;
        max-width: 33.33%;
        min-width: 70px;
        border-right: 1px solid $grey-shade13;
        padding: 0 15px;
        &:first-child{
            padding-left: 5px;
        }
        &:last-child{
            border-right: none;
        }
        p{
            font-size: 12px;
            text-align: left;
            color: $grey-shade26;
            font-weight: 400;
            margin: 0;
        }
        .time{
            font-weight: 700;
            color: $black-shade-3;
        }
    }
}

.fc-timeline-slot-frame{
    border-right: 1px solid #ddd;
    z-index: 0;
}

.fc-theme-standard td{
    border: none!important;
    border-bottom:1px solid #ddd!important;
}
.fc-theme-standard td:last-child{
    border-right: 1px solid #ddd !important;
}
td[data-resource-id="z"] div.fc-datagrid-cell-cushion .fc-datagrid-cell-main{
    -webkit-box-shadow: none;
    box-shadow: 0 0 10px $dark-gray-3;
    background: #333333;
    color: white;
    font-weight: 600;
    padding: 16px 5px !important;
}
.schedular-header{
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 22px 0 $dark-gray-3;
    h5{
        font-size: 18px;
    }
    .image-block{
        max-width: 23px;
        margin-right: 5px;
    }
    .video-link-outer{
        top: 7px;
        right: 30px;
    }
}
.schedular-outer{
    .right-section-wrapper{
        border: none;
        box-shadow: 0 0 22px 0 $dark-gray-3;
    }
}
.fc-dayGridMonth-view{
    .fc-col-header-cell-cushion{
        color: $black-shade-3;
        font-weight: 400;
    }
    .fc-daygrid-day-top{
        a{
            color: $black-shade-3;
            &:hover{
                text-decoration:none !important;
            }
        }
    }
}
[data-resource-id="z"]{
    .right-block p{
      font-size: 16px;
      line-height: 20px;
      color: $white !important;
      font-weight: 500;
  }
  .resource-profile{
    border: none !important;
    background: none !important;
    margin: 0;
  }
} 
.fc-event{
    &.fc-timeline-event{
        background: $white;
        color: $black;
        box-shadow: 0 0 10px $dark-gray-3;
        border: none;
        padding: 0;
        .event-slide{
            color: $grey-shade2;
            font-size: 14px;
            padding: 5px;
            border-left: 4px solid $yellow-shade-3;
            position: relative;
            // height: 80px;
            
            span{
                display: block;
                font-style: normal;
                font-weight: 400;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 100%;
                &.time-block{
                    margin: 0;
                    i{
                        margin-right: 5px;
                    }
                }
            }
        }
    }  
}
.close-job-icon{
    position: absolute;
    right: 4px;
    top: 4px;
    button{
        background: $white;
        border: none;
        outline: none;
        color: $red-shade-1;
        i{
            font-size: 14px;
        }
    }
}
.more-options{
    position: absolute;
    right: 4px;
    top: 4px;
    i{
        padding: 4px;
        font-size: 19px;
        color: $blue-shade-4;
        &:hover{
            background: rgba(0, 0, 0, 0.07);
        }
    }
    &:hover{
        .options-list{
            opacity: 1;
            visibility: visible;
        }
    }
    .options-list{
        position: absolute;
        min-width: 160px;
        border: 1px solid rgba(0,0,0,.15);
        background-color: $white;
        padding: 5px 0;
        right: -5px;
        opacity: 0;
        visibility: hidden; 
        li{
            span{
                display: block;
                padding: 4px 20px;
                text-align: center;
                color: $grey-shade2;
                min-height: 30px;
                &:hover{
                    background-color: $blue-shade-4;
                    color: $white;
                }
            }
            &.carry-over-option{
                span{
                    &:hover{
                        background-color: $black;
                        color: $white;
                    }
                }
            }
        }
    }
}
.calender-tooltip{
    &.show{
        opacity: 1;
    }
    .tooltip-arrow{
        color: white !important;
    }
    &.bs-tooltip-end{
        .tooltip-arrow{
            &::before{
                border-right-color: $white;
                border-width: 10px;
                z-index: 1;
            }
        }
    }
    &.bs-tooltip-start{
        .tooltip-arrow{
            &::before{
                border-left-color: $white;
                border-width: 10px;
                z-index: 1;
            }
        }
    }
    &.bs-tooltip-bottom{
        .tooltip-arrow{
            &::before{
                border-bottom-color: $white;
                border-width: 10px;
                z-index: 1;
            }
        }
    }
    &.bs-tooltip-top{
        .tooltip-arrow{
            &::before{
                border-top-color: $white;
                border-width: 10px;
                z-index: 1;
            }
        }
    }
    .tooltip-arrow::after{
        content: "";
        position: absolute;
        // border-color: transparent;
        // border-style: solid;
        // border-right-color: rgba(0,0,0,.2);
        // border-width: 10px;
        z-index: 0;
        right: 0;
    }
    .tooltip-inner{
        border: 1px solid rgba(0,0,0,.2);
        background-color: $white;
        text-align: left;
        color: $black-shade-3;
        box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
        border-radius:6px;
        min-width: 270px;
        padding: 0;
        .tooltip-header{
            padding: 8px 14px;
            font-size:13px;
            line-height: 1;
            color: $white;
            border-radius: 6px 6px 0 0;
            font-family: 'Open Sans', sans-serif;
            margin: 1px;
            .co-tag{
                min-height:22px;
                min-width: 22px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .tooltip-content{
        padding: 9px 14px;
        ul{
            list-style-type: disc;
            padding-left: 10px;
            li{
                font-size: 14px;
                line-height: 1.4;
                color: $black-shade-3;
            }
        }
    }
}

.schedular-title{
    margin-bottom: 0px;
}
// .fc .fc-timeline-overlap-enabled .fc-timeline-lane-frame .fc-timeline-events{
//     padding-bottom: 0px !important;
// }
/* for changing the background color to pink*/
// .fc-timeline-lane-frame{
//     background-color: pink;
// }
.row-event{
    position: absolute;
    box-sizing: border-box;
    // z-index: 1 !important;
    opacity:0;
    background: rgba(89, 207, 236, 0.7) url(https://dev.fiiviq.com/public/img/plus.svg) no-repeat center center / 24px 24px;
    transition-duration: 0.6s;
    -webkit-transition-duration: 0.6s;
}
.row-event:hover{
    opacity: 1 !important;
}
.fc-timeline-events{
    height: 65px !important;
    z-index: initial !important;
    .row-event{
        z-index: 1;
    }
    .fc-timeline-event-harness{
        z-index: 2;
    }
}
.fc-unassignedButton-button{
    display: flex !important;
    padding: 2px 15px !important
}
.fc-unassignedButton-button::after{
    content: attr(data-after-content);
    align-items: center;
    display: flex;
    vertical-align: middle;
    margin-top: -7px;
    font-weight: 1000;
    font-size: 20px;
}
.fc-unassignedButton-button::before{
    content: attr(data-before-content);
    align-items: center;
    display: flex;
    vertical-align: middle;
    margin-top: -3px;
    font-weight: 1000;
    font-size: 20px;
}
.fc-theme-standard td{
    // border: 1px solid #ddd !important;
}
.fc-daygrid-day-frame{
    position: relative;
    overflow: hidden;
    z-index: 0;
}
.fc-daygrid-day-bottom{
    display: none !important;
}

.fc .fc-day-other .fc-daygrid-day-top{
    opacity: 1 !important;

    .fc-daygrid-day-number{
        color: #bab9b9 !important;
    }
}
.fc-daygrid-day-number{
    z-index: 7 !important;
}
//CSS for Month View Calendar
td{
    &.fc-daygrid-day{
        border: 1px solid #ddd !important;
    }
}

.fc .fc-daygrid-day-top{
    display: block !important;
}
.fc-daygrid-day-events{
    display: none !important;
}
.fc .fc-daygrid-day-number{
    padding: 0 !important;
}

.main-content{
    height: 100px;
    text-align: right;
    position: relative;

    span{
        position: absolute;
        right: 0;
        z-index: 1;
    }
      .inner-div{
        text-align: left !important;
        background-color: $white;
        height: 100%;

        span{
            text-decoration: none !important;
            color: #337ab7;
            height: 100%;
            top: 0;
            overflow: hidden;
            border-radius: 0 0 0 10rem;
            display: inline-block;
            background: rgba(89, 207, 236, 0.7) url(https://dev.fiiviq.com/public/img/plus.svg) no-repeat center center / 24px 24px;
            position: absolute;
            top: 0;
            transition-duration: 0.6s;
            -webkit-transition-duration: 0.6s;
            opacity: 0;
            z-index: 10;
            width: 100%;
            &:hover{
                opacity: 1;
                height: 100%;
            }
          }
      }

      .inner-in-div{
        position: absolute;
        bottom: 0;
        background: black;
        color: #1cc9e4 !important;
        height: 100%;
        width: 100%
      }
      .inner-in-div div{
        position: absolute;
        bottom: 0;
        padding-left: 4px;
        font-weight: bold;
        z-index: 9;
      }
      .inner-in-div::after{
        content: "";
        background: white;
        display: inline-block;
        width: 200ex;
        height: 200ex;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 100px;
      }
      .active-date{
          color: #1cc9e4;
          font-weight: bolder;
          border: 1px solid #1cc9e4;
          border-radius: 82px;
          padding: 0px;
          padding-left: 7px;
          padding-right: 7px;
      }
}

.job-content-main{
    background: rgb(219, 219, 225);
    position: relative;
    color: #333;
    min-width: 70px;
    .total-events{
        font-weight: bold;
        font-size: 14px;
    }
    .create-job-btn{
        border: 0;
        margin: 7px 5px;
        // padding: 2px 15px;
        border-radius: 100px;
        background: #11cfed;
        color: $white;
        width: 100%;
        height: 25px;
        font-size: 12px;
    }
    .show-events-box{
        position: absolute;
        top: 1px;
        right: 4px;
        &:hover{
            .events-list{
                opacity: 1;
                visibility: visible;
            }
        }
        i{
            padding: 4px;
            font-size: 19px;
            color: #1cc9e4;
        }
        .events-list{
            position: absolute;
            min-width: 230px;
            border: 1px solid rgba(0, 0, 0, 0.15);
            background-color: #fff;
            padding: 5px 0;
            right: -5px;
            opacity: 0;
            visibility: hidden;

            .event{
                padding: 5px;
                border: 0;
                line-height: normal;
                border-left: 3px solid #f9990b;
                background-color: #fff;
                color: #333;
                -webkit-box-shadow: 0 0 10px $dark-gray-3;
                box-shadow: 0 0 10px $dark-gray-3;
            }
        }
    }
}

.detail-table-wrapper{
    background: #e7eaf0;
    .react-bootstrap-table{
        margin-top: 10px;
        font-size: 14px;
        color: $black-shade-3;
        font-family: "Open Sans", sans-serif;
        .table-bordered {
            thead {
                tr{
                    border-bottom: 1px solid $dark-gray !important;
                    td{
                        font-weight: 400;
                        padding: 15px 8px;
                    }
                }
            }
            tbody{
                tr{
                    border-bottom: 1px solid $dark-gray;
                    td{
                        font-weight: 400;
                        padding: 5px 5px;
                    }
                }
            }
            .block-wrap{
                span{
                    max-width: calc(100% - 46px);
                }
            }
            .text-nowrap{
                white-space: nowrap;
            }
            .rounded-pill{
                min-width: 100px;
                text-align: center;
            }
            .more-options{
                position: relative;
                .options-list{
                    z-index: 9;
                    min-width: 110px;
                    li{
                        a{
                            display: block;
                        }
                        span{
                            text-align: left;
                            padding: 5px;
                        }
                    }
                }
            }
        }
    }
    .table-header{
        box-shadow: 0 0 22px 0 $dark-gray-3;
        padding: 5px 25px;
        .left-block{
            span{
                font-size: 14px;
                font-weight: 600;
                color: $black-shade-3;
            }
        }
        .time-block{
            p{
                font-size: 12px;
                color: $grey-shade26;
            }
            b{
                font-size: 14px;
                font-weight: 700;
                color: $black-shade-3;
            }
        }
        .col{
            font-weight: bold;
        }
    }
    .action-btn{
        i{
            padding: 4px;
            font-size: 19px;
            color: #1cc9e4;
        } 
    }
}
.noHover {
    *,&:before ,&:after{
        opacity: 0!important;
        visibility: hidden!important;
    }
    background-color: $red-shade-8 !important;
    pointer-events: none;
    background-image: none !important;
    opacity: 0.3 !important;

}
.dot-status{
    width:13px;
    height:13px;
}

.left-stripe-primary-black:after{
    content: "";
    position: absolute;
    left: -4px !important;
    right: auto !important;
    top: 0 !important;
    bottom: 0 !important;
    height: 100%;
    width: 8px;
    display: block;
    background: repeating-linear-gradient(-45deg, #47c9ea, #47c9ea 5px, #000000 5px, #000000 10px ) !important;
    z-index: 2 !important;
}

.unassigned-list{
    .job-tiles-wrapper {
        .custom-scrollbar .thumb-vertical.black-thumb{
            background: #e64b3b !important;
        }
    }
}

.booked-job-event{
    min-width: 80px !important;
}