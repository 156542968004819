.modal-dialog{
    font-family: 'Open Sans', sans-serif;
    &.modal-lg{
        max-width: 900px;
    }
    &.modal-md{
        max-width: 600px;
    }
    .modal-content{
        border-radius: 10px;
    }
    .modal-header{
        border-radius: 10px 10px 0 0;
        background: linear-gradient(90deg, rgb(91, 207, 237), black) no-repeat;
        padding: 12px 15px;
        .modal-title{
            font-size: 18px;
            font-weight: 400;
            color: $white;
            width: 100%;
            font-family: 'Source Sans Pro', sans-serif;
        }
        .close-btn{
            background-color: transparent !important;
            border-color: transparent !important;
            border-radius: 0;
            height: 30px;
            width: 30px;
            padding: 0;
            line-height: 1;
            &:hover{
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
    }
    .modal-body{
        padding: 12px 15px;
    }
    .modal-footer{
        .btn{
            height: 34px;
            line-height: 1;
            min-width: 71px;
            font-size: 14px;
        }
    }
}
.create-job{
    .modal-body{
        padding-bottom: 0;
    }
    .tab-content{
        padding: 15px 0 0; 
        .search-wrapper{
            .title{
                display: inline-block;
                margin-bottom: 5px;
            }
            .form-group{
                position: relative;
                max-width: 500px;
                .form-control{
                    border: 1px solid $dark-gray;
                    border-radius: 20px;
                    height: 40px;
                    padding: 6px 200px 6px 30px;
                    &::placeholder{
                        font-weight: 500;
                        font-size: 12px;
                        color: $grey-shade2;
                    }
                }
                .btn-primary{
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 40px;
                    font-size: 14px;
                    min-width: 211px;
                    span{
                        margin-right: 10px;
                        font-size: 23px;
                        line-height: 0;
                        top: 3px;
                        position: relative;
                    }
                }
            }
            &.full-width{
                .form-group{
                    max-width: 100%;
                    .form-control{
                        padding: 0 30px 0 35px;
                    }
                }
                .input-wrapper{
                    position: relative;
                    .search-icon{
                        position: absolute;
                        top: 13px;
                        left: 13px;
                        z-index: 1;
                        color: $grey-shade21;
                    }
                }
            }
        }
        .select-wrapper{
            .custom-normal-select{
                margin-bottom: 15px;
                flex: 0 0 calc(50% - 15px);
            }
            select, .form-group{
                width: 100%;
            }
        }
        .search-list{
            overflow-x: hidden;
            li{
                a{
                    padding: 10px 20px 10px 12px;
                    font-size: 14px;
                    display: block;
                    color: $black-shade-3;
                    border-bottom: 1px solid $grey-shade13;
                    transition: all 0.3s;
                    border-left: 5px solid transparent;
                    &:hover{
                        background-color: $blue-shade-8;
                        color: $white;
                    }
                }
                &.active{
                    a{
                        border-left: 5px solid $blue-shade-9 !important;
                        font-weight: 700;
                        background-color: $blue-shade-8;
                    }
                }
            }
            .thumb-vertical{
                right: 0;
            }
            &.with-close-icon{
                li {
                    a{
                        border-left: none;
                        align-items: center;
                    }
                }
            }
        }
        .quote-detail-wrapper{
            align-items: flex-end;
            margin-top: 15px;
            h3{
                font-size: 14px;
                font-weight: 700;
            }
            .quote-detail{
                flex: 0 0 calc(50% - 15px);
                max-width: calc(50% - 15px);
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: 100%;
                    top: 0;
                    bottom: 0;
                    background-color: $grey-shade13;
                    right: -15px;
                }
                &:last-child{
                    &::after{
                        display: none;
                    }
                }
                .quote-block-outer{
                    .quote-block{
                        padding: 10px;
                        text-align: center;
                        margin-top: 15px;
                        background: $dark-gray-3;
                        color: $black;
                        flex: 0 0 100%;
                        max-width: 100%;
                        &.width-33{
                            flex: 0 0 calc(33.33% - 10px);
                            max-width: calc(33.33% - 10px);
                        }
                        &.width-50{
                            flex: 0 0 calc(50% - 10px);
                            max-width: calc(50% - 10px);
                        }
                        &.description-block{
                            min-height: 98px;
                        }
                    }
                }
            }
        }
        .activity-wrapper{
            align-items: flex-start;
            margin-bottom: 30px;
            .activity-list{
                max-width: 70%;
                .form-check{
                    position: relative;
                    padding: 0;
                    cursor: pointer;
                    margin: 0 10px 10px 0;
                    input{
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        height: 100%;
                        width: 100%;
                        margin: 0;
                        opacity: 0;
                        visibility: hidden;
                        &:checked +.form-check-label{
                                    background-color: $blue-shade-10;
                                    color: $white;
                        }
                    }
                }
                .form-check-label{
                    display: inline-flex;
                    min-width: 90px;
                    min-height: 30px;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid $blue-shade-10;
                    border-radius: 15px;
                    color: $black-shade-3;
                    cursor: pointer;
                    font-size: 14px;
                    font-family: 'Source Sans Pro', sans-serif;
                }
            }
            .activity-btn{
                height: 30px;
                line-height: 1;
                font-size: 12px;
                background-color: $blue-shade-7;
                border-color: $blue-shade-7;
                min-width: 90px;
                &:hover{
                    background-color: $black-shade;
                    border-color: $black-shade;
                    color: $white;
                }
            }
        }
        .add-activity{
            justify-content: flex-end;
            .form-group{
                width: 420px;
                position: relative;
                .form-control{
                    height: 34px;
                    &::placeholder{
                        font-weight: 500;
                        font-size: 12px;
                        color: $grey-shade2;
                    }
                }
            }
            .btn-wrapper{
                position: absolute;
                right: 0;
                top: 0;
                .btn{
                    height: 34px;
                    line-height: 1;
                    border-radius: 0;
                    font-size: 14px;
                }
                .btn-success{
                    border-radius: 0 5px 5px 0;
                }
            }
        }
        .multi-select-wrapper{
            margin: 15px 0;
            span{
                display: inline-block;
                margin-bottom: 5px;
            }
        }
        .description {
            span{
                margin-bottom: 5px;
                display: inline-block;
            }
            textarea{
                border: 1px solid $dark-gray-1;
            }
        }
       .jobs-description{
          .form-outer{
              .custom-normal-select{
                  flex: calc(25% - 15px);
                  max-width: calc(25% - 15px);
              }
              .select-wrapper{
                  width: calc(50% - 15px);
                  max-width: calc(50% - 15px);
                  .custom-select {
                      height: 40px;
                      padding: 0;
                  }
              }
          }
          .multi-select-wrapper{
              .select__indicators {
                  display: none;
              }
          }
          .select-options{
              align-items: flex-start;
              label{
                  display: block;
                  width: 100%;
                  margin-bottom: 15px;
              }
              .radio-wrapper{
                  flex-wrap: wrap;
                  .form-check-label{
                      width: auto;
                      display: inline-block;
                  }
                  .radio-btn{
                      flex: 50%;
                      max-width: 50%;
                      margin: 0 !important;
                  }
              }
              .multi-select-wrapper{
                  margin: 0;
              }
              .multi-select-wrapper,.radio-wrapper{
                  flex: calc(50% - 15px);
                  max-width: calc(50% - 15px);
              }
          }
       }
       .date-time-wrapper{
        width: 100%;
        .form-control,input{
            font-size: 14px;
            font-weight: 400;
            color: $grey-shade1;
        }
       }
    }
}

.add-customer-vehicle{
    .tab-content{
        margin: 0 15px;
        padding: 15px;
        .content-wrapper{
            margin-right: 50px;
            .form-control,.form-select{
                min-height: 42px;
            }
            .form-outer{
                // .width-50{
                //     flex: calc(50% - 15px);
                //     max-width: calc(50% - 15px);
                // }
                // .width-33{
                //     flex: calc(33.33% - 15px);
                //     max-width: calc(33.33% - 15px);
                // }
                textarea{
                    min-height: 64px;
                }
                label{
                    margin-bottom: 5px;
                }
            }
        }
        .link-wrapper{
            .btn{
                color: $blue;
                border: none;
                font-size: 14px;
                &:hover{
                    background-color: transparent;
                    color: $blue-shade-5;
                }
            }
        }
        .lookup-dropdown{
            display: inline-flex;
            margin-left: 10px;
            position: relative;
            .btn{
                height: 31px;
                line-height: 1;
                font-size: 12px;
                i{
                    margin-right: 5px;
                    font-size: 14px;
                }
            }
            .dropdown-list{
                position: absolute;
                background: #fff;
                border: 1px solid rgba(0,0,0,.15);
                top: 100%;
                right: 0;
                display: none;
                li {
                    a{
                        text-align: center;
                        display: block;
                        padding: 7px 20px;
                        right: 0;
                        min-width: 135px;
                        color: $grey-shade2;
                        margin-right: 10px;
                        &:hover{
                            background-color: $blue-shade-4;
                            color: $white;
                        }
                    }
                }
            }
            &.active{
                .dropdown-list{
                    display: block;
                }
            }
        }
        .hidden-form{
            align-items: flex-end;
            display: none;
            &.active{
                display: flex;
            }
        }
    }
}
.preview-dialog{
    .modal-body{
        padding: 0;
        .column-wrapper{
            padding: 15px 0;
            .left-column{
                padding: 0 15px;
                flex: 0 0 50%;
            }
            .right-column{
                padding: 0 15px;
                flex: 0 0 50%;
                .panel-heading{
                    border-bottom: 1px solid #f3f3f3;
                    display: block;
                    padding: 15px;
                }
                .preview-outer{
                    padding: 15px;
                }
                .preview-wrapper{
                    box-shadow: 0 0 22px 0 #d8d8d8;
                    border-radius: 5px;
                    .panel-body{
                        padding: 15px;
                    }
                }
                &.width-100{
                    flex:0 0 100%;
                    .preview-outer{
                        padding: 0;
                    }
                }
            }
        }
        textarea{
            min-height: 82px !important;
        }
        .form-label{
            color: $black-shade-3;
            font-weight: 400;
            margin-bottom: 5px;
        }
        .form-control{
            font-size: 12px;
            font-weight: 400;
        }
        .form-select{
            min-height: 42px;
        }
        .msg-content{
            border-radius: 15px;
            background: $blue-shade-3;
            padding: 20px;
            font-size: 14px;
            font-weight: 600;
            color: $white;
            position: relative;
            margin-right: 15px;
            &:after{
                content: "";
                width: 0;
                height: 0;
                position: absolute;
                border-left: 15px solid $blue-shade-3;
                border-right: 15px solid transparent;
                border-top: 15px solid $blue-shade-3;
                border-bottom: 15px solid transparent;
                right: -16px;
                top: 0;
            }
        }
    }
}
.sms-balance-dialog,.rogo-lookup{
    .modal-body{
        padding: 20px 0;
    }
    .btn-wrapper{
        .btn-primary{
            min-width: 250px;
        }
    }
    h6{
        font-size: 20px;
        font-weight: 500;
        color: $black-shade-3;
    }
    .chart-data{
        margin: 20px 0;
    }
}
.sms-balance-active,.rego-lookup-active{
    .modal-body{
        padding: 0 15px;
    }
    .img-block{
        border-bottom: 1px solid $grey-shade13;
        margin-bottom: 10px;
        img{
            max-width: 130px;
            border-radius: 100%;
        }
    }
    p{
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 10px;
    }
    .btn-outline-primary{
        height: 34px;
        font-size: 14px;
        line-height: 1;
        width: 100%;
    }
    .btn-wrapper{
        padding: 15px;
    }
}
.rego-lookup-active{
    .img-block{
        padding: 10px;
    }
}
.tab-radio-btn{
    &.radio-wrapper{
        padding-bottom: 15px;
        .form-check{
            position: relative;
            padding: 0;
            cursor: pointer;
            margin: 0 !important;
            input{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
                margin: 0;
                opacity: 0;
                visibility: hidden;
                &:checked +.form-check-label{
                            background-color: $blue-shade-7;
                            color: $white;
                }
            }
            &:first-child{
            .form-check-label{
                border-radius: 20px 0 0 20px;
            }
        }
        &:last-child{
            .form-check-label{
                border-radius: 0 20px 20px 0;
            }
        }
        }
        .form-check-label{
        border: none;
        background-color:transparent;
        margin: 0;
        padding: 9px 14px;
        border-radius: 0;
        font-weight: 400;
        color: $grey-shade2;
        font-size: 14px;
        line-height: 14px;
        width: 100%;
        border: 1px solid $blue-shade-7;
        &.active{
            background-color: $blue-shade-7;
            border: 1px solid $blue-shade-7;
            color: $white;
        }
        &:hover{
            background-color: $blue-shade-7;
            border: 1px solid $blue-shade-7;
            color: $white;
        }
        }
        &.green-radio{
            .form-check-label{
                padding: 6px 14px;
                border-color: $green-shade4;
                &:hover{
                    background-color: $green-shade4;
                }
            }
            .form-check{
                input{
                    &:checked +.form-check-label{
                        background-color: $green-shade4;
                    }
                }
            }
            .form-control{
                height: 33px;
                border-radius: 0 2px 2px 0;
                max-width: 50px;
                &:focus{
                    border-color: $green-shade4;
                }
            }
        }
        &.no-radius{
            .form-check-label{
                height: 33px;
                padding: 8px 14px;
            }
            .form-check:first-child {
                .form-check-label{
                    border-radius: 2px 0 0 2px;
                }
            }
            .form-check:last-child {
                .form-check-label{
                    border-radius: 0 2px 2px 0;
                }
            }
        }
    }
    &.yes-no-radio{
        padding-bottom: 0;
        .form-check-label{
            min-width: 75px;
            text-align: center;
        }
        &.has-3-option{
            .form-check-label{
                min-width: auto;
            }
        }
    }
}
.modal.customer-detail{
  border-radius: 7px;
  .close-btn{
    background: transparent;
    border: none;
    color: #000;
    position: absolute;
    right: 0px;
    top: -12px;
    font-weight: 600;
    font-size: 30px;
    color: #333333;
  }
  .stepper-content {
    padding: 0;
    .customer-detail-block{
      padding: 0;
    }
    .form-detail-outer {
      .left-block{
        flex: 0 0 65%;
        max-width:65%;
        border:none;
        padding-right: 30px;
      }
      .right-block{
        flex: 0 0 35%;
        max-width:35%;
      }
    }
  }
}
.option-after-jobCreate{
    .modal-body{
        padding: 30px 15px;
        .btn{
            height: 34px;
            font-size: 14px;
            line-height: 1;
            margin: 0 10px 15px;
        }
    }
}
.reschedule-job-modal{
    .date-block-outer{
        max-width:340px;
        padding: 5px 10px;
        box-shadow: 0 0 22px 0 $dark-gray-3;
        border-radius: 5px;
        min-height: 52px;
        .ant-picker{
            min-width: 245px;
        }
        .date-picker {
            display: flex;
            label{
                white-space: nowrap;
                margin: 0 10px 0 0;
            }
            .date-set{
                top:0;
            }
            input{
                color: $grey-shade2;
                font-family: 'Source Sans Pro', sans-serif;
                font-weight: 400;
                &::placeholder{
                    font-family: 'Source Sans Pro', sans-serif;
                    font-weight: 400;
                }
            }
        }
    }
    .date-time-wrapper{
        width: 100%;
    }
    .small-accordion{
        border-top:1px solid $dark-gray-6;
        padding-top: 24px;
    }
    .form-check{
        &.disable{
          cursor: not-allowed;
        }
        .form-check-input{
          &:disabled{
            + .form-check-label{
              background-color: $dark-gray-3;
              opacity: 1;
              cursor: not-allowed;
              &.red-disable{
                background-color: #ff000080;
                color: $white;
              }
            }
          }
        }
      }
    .display-index{
        height: 28px;
        width: 28px;
        min-width: 28px;
        border-radius: 100%;
        border: 1px solid $black-shade-3;
        color: $black-shade-3;
        font-size: 18px;
        line-height: 1;
        font-weight: 600;
    }
    .move-to-co{
        width: 100%;
        max-width: 100%;
        margin-right: 20px;
    }
    .job-id{
        margin-left: 40px;
    }
    .job-label{
        background: $black;
        border-radius: 100%;
        font-size: 18px;
        font-weight: 400;
        padding: 0 3px;

    }
}

.pricing-discount{
    .modal-header{
        padding: 8px 15px;
    }
    .discount-block{
        display: flex;
        flex-direction: row;
    }
    .apply-btn {
        height: 34px !important;
        line-height: 1;
        font-size: 14px;
        margin: 0 20px 0 0;
        min-width: 200px;
    }
    .tab-radio-btn{
        width: 100%;
        margin-bottom: 20px;
        .form-check{
            width: 33.33%;
            text-align: center;
        }
    }
    .content-wrapper{
    margin-bottom: 40px;
    width: 100%;
    padding-right: 10px;
    .tab-radio-btn{
        padding-bottom: 0;
        margin-bottom: 0;
        .radio-btn{
            width:150px;
            .form-check-label{
                text-align: center;
            }
        }
        .form-control{
            max-width: calc(100% - 300px);
            text-align: center;
        }
    }
    .form-group{
        width: 100%;
        .form-control{
        height: 36px !important;
        &::placeholder{
            font-weight: 400;
            opacity: 1;
        }
        }
    }
    .select-wrapper{
        .form-group{
        .form-control{
            padding-top:0 !important;
        }
        }
    }
    .select-wrapper{
        [class*="-control"]{
        min-height: 32px !important;
        }
        [class*='indicatorContainer']{
        padding: 2px 8px;
        }
        .css-6j8wv5-Input{
        margin:0 !important; 
        }
    }
    }
    .applied-discount{
    p{
        font-size: 12px;
    }
    .amount{
        font-size: 16px;
        font-weight: 600;
    }
    }
}

.delete-record-dialog,.info-dialog{
    .modal-dialog{
        max-width: 320px;
        .modal-content{
           border-radius: 10px;
        }
        .modal-body{
            padding: 15px;
        }
    }
    .heading-block{
        padding: 10px;
        background-color: $red-shade-2;
        border-radius: 10px 10px 0 0;
        .close-icon{
            font-size: 60px;
            color: $white;
        }
    }
    h2{
        font-size: 30px;
        font-weight: 600;
        font-family: 'Source Sans Pro', sans-serif;
        color: $dark-gray-9;
        text-align: center;
    }
    .btn{
        height: 32px;
        line-height: 1;
        font-size: 14px;
        min-width: 72px;
        margin: 0 5px;
    }
}
.move-unassigned-dialog{
    .modal-dialog{
        max-width: 350px;
    }
    .dialog-title{
        padding: 10px;
        font-size: 24px;
        line-height: 1.3;
        font-family: 'Source Sans Pro', sans-serif;
        border-bottom: 1px solid $grey-shade13;
        color: #545454;
        .text-danger{
            color: $red-shade-1 !important;
        }
    }
}

.edit-kit{
    .select-wrapper{
        .form-group{
            width: 100%;
        }
    }
}
.info-dialog{
    .heading-block{
        background-color: $blue-shade-7;
        .info-icon{
            font-size: 60px;
            color: $white;
        }
    }
}
.monthView-create-job{
    .border-block{
        margin:20px 0;
        border-bottom: 1px solid $dark-gray-6;
    }
}
.time-clock-dialog{
    .modal-dialog{
        max-width: 420px;
    }
    .content-block{
        box-shadow: 0 0 22px 0 $dark-gray-3;
    }
    .top-block{
        padding: 15px;
        border-bottom: 1px solid $grey-shade6;
        .time{
            color:$green-shade6;
            font-family: "Open Sans", Helvetica, Arial, sans-serif;
            font-size: 18px;
            text-transform: uppercase;
        }
        .status{
            color:#CC931A;
            font-size:13px;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
    }
    .checkin-checkout-btn-block {
        .btn-wrapper{
            flex:0 0 100%;
            max-width: 100%;
            border: none;
            padding: 25px;
        }
    }
}
.monthView-create-job{
    .border-block{
        margin:20px 0;
        border-bottom: 1px solid $dark-gray-6;
    }
}
.info-dialog{
    .heading-block{
        background-color: $blue-shade-7;
        .info-icon{
            font-size: 60px;
            color: $white;
        }
    }
}
.move-unassigned-dialog{
    .modal-dialog{
        max-width: 350px;
    }
    .dialog-title{
        padding: 10px;
        font-size: 24px;
        line-height: 1.3;
        font-family: 'Source Sans Pro', sans-serif;
        border-bottom: 1px solid $grey-shade13;
        color: #545454;
        .text-danger{
            color: $red-shade-1 !important;
        }
    }
}

    .edit-kit{
        .select-wrapper{
            .form-group{
                width: 100%;
            }
        }
    }

.vehicle-details-dialog{
    .detail-header{
        color: $black-shade-3;
    }
    .detail-value{
        background-color: $grey-shade8;
        color: $black-shade-3;
        font-weight: 700;
        margin-bottom: 20px;
    }
    .row{
        margin: 0;
    }
    .col-sm-3{
        padding: 5px;
    }
    .title{
        font-size: 18px;
        font-weight: 700;
        font-family: 'Source Sans Pro', sans-serif;
    }
}
