// calender
@import url("https://cdn.syncfusion.com/ej2/material.css");

// fonts
@import "Montserrat/stylesheet.css";
@import "Open_Sans/stylesheet.css";
@import "Source-Sans-Pro/stylesheet.css";

// icons
@import "custom-fonts/font-awesome.min.css";
@import "custom-fonts/line-awesome.min.css";
@import "custom-fonts/ficons.min.css";

// css
@import "color.scss";
@import "antd/dist/antd.css";
@import "react-toastify/dist/ReactToastify.css";
@import "time-picker.css";
@import "break-point.scss";
@import "form-element.scss";
@import "typography.scss";
@import "pre-login.scss";
@import "header.scss";
@import "sidebar.scss";
@import "dialog.scss";
@import "custom.scss";
@import "dark-theme.scss";
@import "scheduler.scss";

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: auto;
}

.pagination-container {
  span,
  i {
    color: rgb(109, 105, 105);
    margin: 0px 10px;
    &:hover {
      cursor: not-allowed;
    }
  }
}
.dropdown-menu {
  li {
    a {
      font-size: 15px;
      color: rgb(72, 71, 71);
      &:hover {
        background: #11cfed !important ;
        color: white;
      }
    }
  }
}

.scroller {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 2px solid black;
  position:fixed;
  top: 266px;
  right: 28px;
  z-index: 99;
  width: 1.4rem;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  background: rgb(2,0,36);
background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(61,222,255,0.958420868347339) 0%, rgba(206,206,215,1) 100%);
  border-radius:4px;

  span {
    margin: 0px 0px 0.6px 0px;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom:1px solid white;

    i{
      font-size: 20px;
    }

    &:hover{
  background: #4c4d4e;

    }
  }
  span:nth-child(4){
    border:none;
   
  }
}
